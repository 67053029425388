<div class="audience">
	<!-- <button mat-mini-fab [class]="callClass" color="warn"
		matTooltip="Iniciar o terminar llamada" (click)="toggleStream()">
		<mat-icon [style.color]="isLive? '#388e3c' : '#d32f2f'">light_mode</mat-icon>
	</button> -->
	<mtx-loader
		[loading]="loading || waiting"
		[type]="waiting ? 'progressbar' : 'spinner'"
		[hasBackdrop]="waiting ? false : true"
		[style.width]="event.isVertical ? '100%' : 'fit-content'"
	>
		<mat-button-toggle-group
			name="channelGroup"
			(change)="onChannelGroupChange($event)"
			[disabled]="!event.isActive && !event.isLive"
			[vertical]="event.isVertical"
			[style.width]="event.isVertical ? '100%' : 'auto'"
			multiple="true"
			>
			<mat-button-toggle disabled="true">
				<mat-icon [style.color]="event.isLive ? '#388e3c' : '#d32f2f'">light_mode</mat-icon>
			</mat-button-toggle>
			<mat-button-toggle
				*ngFor="let channel of channels"
				[value]="channel.id"
				[style.background-color]="channel.isDisabled ? 'rgba(0,0,0,0.05)' : channel.backgroundColor"
				[style.color]="channel.isDisabled ? 'rgba(0,0,0,0.5)' : channel.color"
				[style.filter]="channel.isDisabled ? 'opacity(0.5)' : channel.isChecked ? 'brightness(1)' : 'brightness(0.5)'"
				[disabled]="channel.isDisabled"
				[checked]="channel.isChecked"
			>
				{{ channel.alias !== null ? channel.alias : channel.name }}
			</mat-button-toggle>
		</mat-button-toggle-group>
	</mtx-loader>
</div>
