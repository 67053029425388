<h1 mat-dialog-title>Acuerdo de Licencia</h1>
<div mat-dialog-content>
  <p style="text-align: justify;">Al continuar al sitio el usuario del programa “INTERWISE” reconoce que “INTERWISE” es
    propiedad intelectual debidamente protegida por el Derecho de Autor a la cual se le concede
    acceso por única vez exclusivamente para su uso por el periodo de tiempo de duración del evento
    contratado y acepta que no puede utilizar ni el programa ni la marca “INTERWISE” en adelante en
    su beneficio.</p>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button mat-raised-button mat-dialog-close cdkFocusInitial color="primary">Aceptar</button>
</div>