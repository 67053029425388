<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
	<mat-card id="carddd" style="min-height: 100%; padding: 0px; display: flex; flex-direction: column;">
		<mat-card-title-group style="flex-direction: column; justify-content: start;">
			<img mat-card-image [src]="headerImageUrl" [style.backgroundColor]="headerBackgroundColor" style="margin: 0px; width: 100%;">
			<div [style.backgroundColor]="headerBackgroundColor" style="height: 15px; filter: opacity(0.70); border-width: 0; width: 100%;"></div>
			<div [style.backgroundColor]="headerBackgroundColor" style="height: 15px; filter: opacity(0.40); border-width: 0; width: 100%;"></div>
			<div [style.backgroundColor]="headerBackgroundColor" style="height: 15px; filter: opacity(0.10); border-width: 0; width: 100%;"></div>
		</mat-card-title-group>

		<mat-card-header style="padding: 24px 0px;" >
			<mat-card-title style="font-size: 30px; text-align: center;" [style.color]="headerTextColor">{{headerText}}</mat-card-title>
		</mat-card-header>

		<mat-card-content style="background-color: white; flex-grow: 1; display: flex; flex-direction: column;">
			<app-audience-embeddable (downlinkNetworkQualityEvent)="onDownlinkNetworkQualityChanged($event)" (uplinkNetworkQualityEvent)="onUplinkNetworkQualityChanged($event)"></app-audience-embeddable>
		</mat-card-content> 

		<mat-card-actions style="margin-left: 0; margin-right: 0;">
			<div style="display:flex; flex-direction: column; justify-content: flex-start;">
				<a *ngIf="supportLink" [href]="supportLink" target="_blank" mat-raised-button [style.backgroundColor]="linkBackgroundColor" [style.color]="linkTextColor" style="width: 150px; margin-top: 16px; margin-left: auto; margin-right: auto;"><mat-icon aria-hidden="false">whatsapp</mat-icon>Soporte Técnico</a>
				<a *ngIf="programLink" [href]="programLink" target="_blank" mat-raised-button [style.backgroundColor]="linkBackgroundColor" [style.color]="linkTextColor" style="width: 150px; margin-top: 16px; margin-left: auto; margin-right: auto;"><mat-icon aria-hidden="false">event_note</mat-icon>Programa</a>
			</div>
		</mat-card-actions>

		<mat-card-footer style="margin-left: 0px; margin-right: 0px; display: flex; flex-direction: row; justify-content: end;" [style.backgroundColor]="footerBackgroundColor">
			<a [href]="footerImageLink" target="_blank">
				<img [src]="footerImageUrl" style="height: 80px; margin: 8px 16px;" />
			</a>
		</mat-card-footer>

		<mat-card-footer style="margin-top: 0px; margin-bottom: 0px; margin-left: 0px; margin-right: 0px; display: flex; flex-direction: column; justify-content: center;" [style.backgroundColor]="footerBackgroundColor2">
			<mat-toolbar style="font-size: 8px;" [style.backgroundColor]="footerBackgroundColor2">
				<mat-icon [style.color]="networkQualityIconColor" matTooltip="Calidad de la conexión">{{networkQualityIcon}}</mat-icon>
				
				<span class="spacer"></span>
				<span>
					&copy;Derechos Registrados Interwise&reg; 2023. Zapopan, Jalisco, México.
				</span>
			</mat-toolbar>
		</mat-card-footer>
	</mat-card>
</mtx-loader>
