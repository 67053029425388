import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import AgoraRTC, { ConnectionDisconnectedReason, ConnectionState, IAgoraRTCClient, NetworkQuality } from 'agora-rtc-sdk-ng';
import AgoraRTM, { RtmChannel, RtmClient, RtmMessage, RtmStatusCode, RtmTextMessage } from 'agora-rtm-sdk';
import { AnalyticsEvent } from 'src/app/enums/analytics-event.enum';
import { AttendeeDto } from 'src/app/models/attendee-dto';
import { ChannelDto } from 'src/app/models/channel-dto';
import { EventMessageDto } from 'src/app/models/event-message-dto';
import { IPDto } from 'src/app/models/ip-dto';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { MessageDto } from 'src/app/models/message-dto';
import { TextMessageDto } from 'src/app/models/text-message-dto';
import { TokenDto } from 'src/app/models/token-dto';
import { AttendeesService } from 'src/app/services/attendees.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { EventsService } from 'src/app/services/events.service';
import { IDService } from 'src/app/services/id.service';
import { IPWhoIsService } from 'src/app/services/ip-who-is.service';
import { LogService } from 'src/app/services/log.service';
import { PlatformService } from 'src/app/services/platform.service';
import { environment } from 'src/environments/environment';
import { UAParser } from 'ua-parser-js';

@Component({
	selector: 'app-audience-embeddable',
	templateUrl: './audience-embeddable.component.html',
	styleUrls: ['./audience-embeddable.component.scss'],
})
export class AudienceEmbeddableComponent implements OnInit, OnDestroy {
	@Output() downlinkNetworkQualityEvent!: EventEmitter<number>;
	@Output() uplinkNetworkQualityEvent!: EventEmitter<number>;

	loading!: boolean;
	waiting!: boolean;

	hasJoined!: boolean;
	callClass!: string;

	rtcClient!: IAgoraRTCClient;
	rtmClient!: RtmClient;
	rtmClientReconnected!: boolean;

	rteToken!: TokenDto;

	rtmChannel!: RtmChannel;

	appId!: string;
	eventId!: string;
	event!: LiveInteractiveAudioStreamingEventDto;
	channels!: ChannelDto[];
	attendeeId!: string | null;
	attendeeIdRequired!: boolean;
	attendee!: AttendeeDto;

	ipData!: IPDto;

	audio!: HTMLAudioElement;
	canPlay!: boolean;

	canWakeLock!: boolean;
	wakeLock!: any;

	myNavigator: any = navigator;

	constructor(
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private snackBar: MatSnackBar,
		private logService: LogService,
		private authService: AuthService,
		private eventsService: EventsService,
		private ipWhoIsService: IPWhoIsService,
		private platformService: PlatformService,
		private idService: IDService,
		private attendeesService: AttendeesService
	) {
		try {
			this.downlinkNetworkQualityEvent = new EventEmitter<number>();
			this.uplinkNetworkQualityEvent = new EventEmitter<number>();

			this.loading = false;
			this.rtmClientReconnected = false;

			this.callClass = 'control';

			this.event = new LiveInteractiveAudioStreamingEventDto();
			this.event.isActive = false;
			this.event.isLive = false;
			this.event.isVertical = false;

			this.channels = new Array(0);

			if ('wakeLock' in navigator) {
				this.canWakeLock = true;
			} else {
				this.canWakeLock = false;
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;

			if (!AgoraRTC.checkSystemRequirements()) {
				this.snackBar.open('¡Error! Tu navegador no es soportado por Interwise', 'Ok', {
					verticalPosition: 'top',
					duration: 3600000,
					panelClass: 'warn',
				});
			}

			this.audio = new Audio();
			this.audio.src = '../../../assets/sounds/alert.mp3';
			this.audio.load();
			this.audio.oncanplay = () => {
				this.canPlay = true;
			};

			this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') ?? '';

			await this.getEvent(this.eventId);

			if (this.event !== null && this.event.isActive) {
				this.logService.logEvent(AnalyticsEvent.hermes_join_event, {
					hermes_event_id: this.eventId,
					hermes_event_name: this.event.name,
				});

				this.attendeeIdRequired = this.event.idRequired;

				if (this.event.idRequired) {
					let email: string | null;
					do {
						email = window.prompt('Cuál tu email?');
					} while (email === null || email === '');
					this.attendeeId = email;
				} else {
					this.attendeeId = await this.authService.getUserId();
				}

				if (this.rteToken === undefined) {
					this.rteToken = await this.eventsService.getAudienceToken(this.eventId, this.attendeeId);
				}

				this.rtmClient = AgoraRTM.createInstance(environment.agoraId, {
					enableLogUpload: false,
				});

				this.rtmClient.on('ConnectionStateChanged', async (newState: RtmStatusCode.ConnectionState, reason: RtmStatusCode.ConnectionChangeReason) => {
					if (newState === AgoraRTM.ConnectionState.RECONNECTING) {
						this.rtmClientReconnected = true;
					} else if (newState == AgoraRTM.ConnectionState.CONNECTED) {
						if (this.rtmClientReconnected) {
							this.rtmClientReconnected = false;
						}
					}

					if (newState === AgoraRTM.ConnectionState.ABORTED && reason === AgoraRTM.ConnectionChangeReason.REMOTE_LOGIN) {
						await this.setDisconnected();
					}

					this.logService.logInformation(`ConnectionStateChanged: ${newState} due to ${reason}`);
				});

				await this.rtmClient.login({
					uid: this.attendeeId,
					token: this.rteToken.rtm,
				});

				const memberCount = await this.rtmClient.getChannelMemberCount([this.eventId]);
				console.log(`MemberCount ${memberCount[this.eventId]}`);

				if (memberCount[this.eventId] < 5 + this.event.channels.length + this.event.audienceSize) {
					const ip = await this.ipWhoIsService.getIPData();
					this.attendee = new AttendeeDto();
					this.attendee.attendeeId = this.attendeeId;
					this.attendee.city = ip.city;
					this.attendee.continent = ip.continent;
					this.attendee.country = ip.country;
					this.attendee.countryCode = ip.country_code;
					this.attendee.eventId = this.event.id;
					this.attendee.eventName = this.event.name;
					this.attendee.ip = ip.ip;
					this.attendee.isp = ip.connection.isp;
					this.attendee.latitude = ip.latitude;
					this.attendee.longitude = ip.longitude;
					this.attendee.state = ip.region;

					const uaParser = new UAParser();
					this.attendee.deviceVendor = uaParser.getDevice().vendor ?? '';
					this.attendee.deviceModel = uaParser.getDevice().model ?? '';
					this.attendee.deviceType = uaParser.getDevice().type ?? '';
					this.attendee.platform = uaParser.getOS().name ?? '';
					this.attendee.browser = uaParser.getBrowser().name ?? '';
					this.attendee.renderingEngine = uaParser.getEngine().name ?? '';

					await this.attendeesService.addAttendee(this.attendee);

					this.rtmChannel = await this.rtmClient.createChannel(this.eventId);
					this.rtmChannel.on('ChannelMessage', (rtmMessage: RtmMessage, memberId: string) => {
						if (rtmMessage.messageType === 'TEXT') {
							const rtmTextMessage = rtmMessage as RtmTextMessage;
							const message = JSON.parse(rtmTextMessage.text) as MessageDto;

							if (message.type === 'TextMessageDto') {
								const textMessage = message as TextMessageDto;
							} else if (message.type === 'EventMessageDto') {
								const eventMessage = message as EventMessageDto;

								if (eventMessage.event === 'ActiveStateChanged') {
									this.activeStateChanged(this.eventId, eventMessage.args as boolean);
								} else if (eventMessage.event === 'LiveStateChanged') {
									this.liveStateChanged(this.eventId, eventMessage.args as boolean);
								}
							}
						}

						this.logService.logInformation(`ChannelMessage: ${rtmMessage}`);
					});

					await this.rtmChannel.join();

					this.rtcClient = AgoraRTC.createClient({
						mode: 'live',
						role: 'audience',
						clientRoleOptions: {
							level: 2,
						},
						codec: 'vp8',
					});

					this.rtcClient.on('network-quality', (networkQuality: NetworkQuality) => {
						this.downlinkNetworkQualityEvent.emit(networkQuality.downlinkNetworkQuality);
						this.uplinkNetworkQualityEvent.emit(networkQuality.uplinkNetworkQuality);
					});

					this.rtcClient.on(
						'connection-state-change',
						async (current: ConnectionState, previous: ConnectionState, reason: ConnectionDisconnectedReason | undefined) => {
							if (current === 'DISCONNECTED' && reason === ConnectionDisconnectedReason.UID_BANNED) {
								await this.setDisconnected();
							}
						}
					);

					if (this.event.isLive) {
						await this.joinStream();
					}
				} else {
					this.loading = false;
					this.waiting = true;

					this.event.isLive = false;

					this.channels.forEach(l => (l.isDisabled = true));
					this.channels.forEach(l => (l.isChecked = false));

					this.ngOnDestroy();

					this.snackBar.open('¡Error! El evento está lleno. Refresca la página en unos minutos para volver a intentar', 'Ok', {
						verticalPosition: 'top',
						duration: 3600000,
						panelClass: 'warn',
					});
				}
			}
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async ngOnDestroy(): Promise<void> {
		try {
			await this.rtcClient?.leave();

			await this.rtmChannel?.leave();

			await this.rtmClient?.logout();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async activeStateChanged(eventId: string, isActive: boolean): Promise<void> {
		try {
			this.event.isActive = isActive;

			if (!this.event.isActive) {
				this.leaveStream();
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async liveStateChanged(eventId: string, isLive: boolean): Promise<void> {
		try {
			this.waiting = !isLive;
			this.event.isLive = isLive;

			if (this.event.isLive) {
				this.rteToken = await this.eventsService.getAudienceToken(eventId, this.attendeeId ?? '');
			}

			if (this.event.isLive) {
				await this.joinStream();
			} else {
				await this.leaveStream();
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async onClose(error: Error | undefined): Promise<void> {
		try {
			if (error !== undefined) {
				this.logService.logError(error);
			}

			await this.leaveStream();

			this.loading = true;
			this.waiting = false;
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async joinStream(): Promise<void> {
		try {
			if (this.event.isActive && this.event.isLive && this.rtcClient.connectionState === 'DISCONNECTED') {
				if (this.rteToken === undefined) {
					this.rteToken = await this.eventsService.getAudienceToken(this.eventId, this.attendeeId ?? '');
				}

				await this.rtcClient.join(environment.agoraId, this.eventId, this.rteToken.rtc, this.attendeeId);

				this.ipData = await this.ipWhoIsService.getIPData();

				// TODO: Record new call...

				this.rtcClient.on('user-published', async (user, mediaType) => {
					const channel = this.channels.filter(l => l.id === user.uid)[0];

					if (channel) {
						channel.isDisabled = false;

						const playPromise = this.audio.play();
						if (playPromise !== undefined) {
							playPromise
								.then(_ => {
									// Auto-play started!
								})
								.catch(_ => {
									// Auto-play was prevented
								});
						}
					}

					// Initiate the subscription
					await this.rtcClient.subscribe(user, mediaType);
				});

				this.rtcClient.on('user-unpublished', async user => {
					const channel = this.channels.filter(l => l.id === user.uid)[0];

					if (channel) {
						channel.isDisabled = true;
						channel.isChecked = false;
					}
				});

				this.rtcClient.remoteUsers.forEach(user => {
					const channel = this.channels.filter(l => l.id === user.uid)[0];

					if (channel) {
						channel.isDisabled = false;
					}
				});

				this.hasJoined = true;
				this.callClass = 'control isActive';

				if (this.canWakeLock) {
					// this.wakeLock = await navigator.wakeLock.request();
					this.wakeLock = await this.myNavigator['wakeLock'].request('screen');
					console.warn('--- Wake Lock ---');
					console.warn(this.wakeLock);
				}
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async leaveStream(): Promise<void> {
		try {
			await this.rtcClient?.leave();

			this.hasJoined = false;
			this.callClass = 'control';

			this.channels.forEach(l => (l.isDisabled = true));
			this.channels.forEach(l => (l.isChecked = false));

			if (this.canWakeLock && this.wakeLock !== undefined) {
				await this.wakeLock?.release();
				this.wakeLock = undefined;

				console.warn('--- Wake Lock Released ---');
				console.warn(this.wakeLock);
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getEvent(eventId: string): Promise<void> {
		try {
			this.event = await this.eventsService.getEvent(eventId);
			this.titleService.setTitle(`${environment.title} | Audiencia Incrustable - ${this.event.name}`);

			this.event.channels.forEach(channel => {
				channel.isDisabled = true;
				this.channels.push(channel);
			});

			this.waiting = !this.event.isLive;
		} catch (e) {
			this.logService.logError(e as Error);

			this.waiting = true;
		}
	}

	onChannelGroupChange(event: MatButtonToggleChange): void {
		try {
			if (event.source !== undefined) {
				if (event.value.length >= 1) {
					this.channels.forEach(l => (l.isChecked = false));
					this.rtcClient.remoteUsers.forEach(u => u.audioTrack?.stop());

					const channelId = event.value[event.value.length - 1];
					const channel = this.channels.filter(c => c.id === channelId)[0];
					if (channel) {
						const remoteUser = this.rtcClient.remoteUsers.filter(u => u.uid === channel.id)[0];

						// Play the audio
						remoteUser.audioTrack?.play();
						channel.isChecked = true;

						this.logService.logEvent(AnalyticsEvent.hermes_join_channel, {
							hermes_event_id: this.eventId,
							hermes_event_name: this.event.name,
							hermes_channel_id: channel.id,
							hermes_channel_name: channel.name,
						});
					}
				} else {
					this.channels.forEach(l => (l.isChecked = false));
					this.rtcClient.remoteUsers.forEach(u => u.audioTrack?.stop());
				}
			} else {
				this.channels.forEach(l => (l.isChecked = false));
				this.rtcClient.remoteUsers.forEach(u => u.audioTrack?.stop());
			}

			// const any = this.channels.some(l => l.isChecked);
			// if (any) {
			// 	this.channels.forEach(l => (l.isChecked = false));
			// 	this.rtcClient.remoteUsers.forEach(u => u.audioTrack?.stop());
			// } else {
			// 	this.channels.forEach(l => (l.isChecked = false));
			// 	this.rtcClient.remoteUsers.forEach(u => u.audioTrack?.stop());

			// 	const source = event.source;
			// 	const channel = this.channels.filter(l => l.id === source.value)[0];
			// 	if (channel) {
			// 		const index = this.channels.indexOf(channel);
			// 		const remoteUser = this.rtcClient.remoteUsers.filter(u => u.uid === channel.id)[0];

			// 		// Play the audio
			// 		remoteUser.audioTrack?.play();
			// 		channel.isChecked = true;

			// 		this.logService.logEvent(AnalyticsEvent.hermes_join_channel, {
			// 			hermes_event_id: this.eventId,
			// 			hermes_event_name: this.event.name,
			// 			hermes_channel_id: channel.id,
			// 			hermes_channel_name: channel.name,
			// 		});
			// 	}
			// }
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async setDisconnected(): Promise<void> {
		this.loading = false;
		this.waiting = true;

		this.event.isLive = false;

		this.channels.forEach(l => (l.isDisabled = true));
		this.channels.forEach(l => (l.isChecked = false));

		await this.ngOnDestroy();
	}
}
