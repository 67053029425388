<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<span><a class="title" routerLink="/events">Eventos</a></span>
		<span style="margin-top: 10px;"><mat-icon>chevron_right</mat-icon></span>
		<span>Evento</span>
		<span class="spacer" style="flex: 1 1 auto"></span>
	</mat-toolbar>
	<mat-card>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<form autocomplete="off" class="eventForm" [formGroup]="eventForm" (ngSubmit)="saveEvent(ef, cf)"
				#ef="ngForm" novalidate>
				<div>
					<mat-form-field appearance="outline" >
						<mat-label>Compañía</mat-label>
						<mat-select formControlName="company" [(ngModel)]="selectedCompany" [disabled]="!isAdmin">
							<mat-option *ngFor="let company of companyList" [value]="company">
								{{ company.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div>
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Nombre</mat-label>
						<input matInput formControlName="name" />
					</mat-form-field>
				</div>
				<div>
					<mat-form-field appearance="outline" style="width: 100%">
						<mat-label>Descripción</mat-label>
						<textarea matInput formControlName="description"></textarea>
					</mat-form-field>
				</div>
				<div class="datetime">
					<mat-form-field appearance="outline">
						<mat-label>Fecha</mat-label>
						<input matInput [matDatepicker]="picker" formControlName="date" />
						<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Hora Inicio</mat-label>
						<mtx-datetimepicker #datetimePickerFrom type="time" mode="auto" startView="clock" twelvehour="false" timeInput="true"></mtx-datetimepicker>
  						<input [mtxDatetimepicker]="datetimePickerFrom" matInput required formControlName="from">
  						<mtx-datetimepicker-toggle [for]="datetimePickerFrom" matSuffix></mtx-datetimepicker-toggle>
					</mat-form-field> 

					<mat-form-field appearance="outline">
						<mat-label>Hora Fin</mat-label>
						<mtx-datetimepicker #datetimePickerTo type="time" mode="auto" startView="clock" twelvehour="false" timeInput="true"></mtx-datetimepicker>
						<input [mtxDatetimepicker]="datetimePickerTo" matInput required formControlName="to">
						<mtx-datetimepicker-toggle [for]="datetimePickerTo" matSuffix></mtx-datetimepicker-toggle>
					</mat-form-field>
				</div>
				<div class="size">
					<mat-form-field appearance="outline">
						<mat-label>Tamaño de Audiencia</mat-label>
						<input matInput type="number" formControlName="audienceSize" />
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Orientación de Canales</mat-label>
						<mat-select formControlName="orientation">
							<mat-option value="h"> Horizontal </mat-option>
							<mat-option value="v"> Vertical </mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field appearance="outline" style="visibility:hidden;">
						<mat-label>Cantidad de Canales</mat-label>
						<input matInput type="number" readonly="readonly" formControlName="hostsNumber" />
					</mat-form-field>
				</div>
				<div class="email" style="display: flex; justify-content: space-between">
					<mat-checkbox formControlName="idRequired">
						ID Requerido
					</mat-checkbox>
				</div>

				<mat-divider></mat-divider>

				<div class="container">
					<div class="headerImage">
						<span class="titleHeaderImage">Imagen (Encabezado)</span>

						<div class="file-upload" style="margin-bottom: 25.5px;">
							<img width="204" height="34" [src]="imageHeaderURL">
							<input class="fileInput" type="file" #imageHeaderUrl accept="image/*" (change)="uploadImage($event, 'header')">
							<button type="button" mat-icon-button (click)="imageHeaderUrl.click()">
								<mat-icon>upload</mat-icon>
							</button>
						</div>
					</div>
					<div class="headerImage">
						<span class="titleHeaderImage">Imagen (Empresa | Pie de Página))</span>

						<div class="file-upload" style="margin-bottom: 25.5px;">
							<img width="204" height="34" [src]="imageFooterURL">
							<input class="fileInput" type="file" #imageFooterUrl accept="image/*" (change)="uploadImage($event, 'footer')">
							<button type="button" mat-icon-button (click)="imageFooterUrl.click()">
								<mat-icon>upload</mat-icon>
							</button>
						</div>
					</div>
				</div>

				<div class="container">
					<mat-form-field appearance="outline">
						<mat-label>Color de Texto (Título)</mat-label>
						<input matInput type="color" formControlName="headerTextColor" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Color de Fondo (Encabezado)</mat-label>
						<input matInput type="color" formControlName="headerBackgroundColor" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Color de Fondo (Pie de Página)</mat-label>
						<input matInput type="color" formControlName="footerBackgroundColor" />
					</mat-form-field>
				</div>

				<div class="container">
					<mat-form-field appearance="outline">
						<mat-label>Título</mat-label>
						<input matInput type="text" formControlName="headerText" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Link Empresa </mat-label>
						<input matInput type="url" formControlName="footerImageLink" />
					</mat-form-field>
				</div>

				<div class="container">
					<mat-form-field appearance="outline">
						<mat-label>Link Programa </mat-label>
						<input matInput type="url" formControlName="programLink" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Link Soporte Técnico</mat-label>
						<input matInput type="url" formControlName="supportLink" />
						<button mat-icon-button matSuffix (click)="setWhatsappLink()" type="button">
							<fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
						</button>
						<button mat-icon-button matSuffix (click)="setTelegramLink()" type="button">
							<fa-icon [icon]="['fab', 'telegram']"></fa-icon>
						</button>
						<button mat-icon-button matSuffix (click)="setMessengerLink()" type="button">
							<fa-icon [icon]="['fab', 'facebook-messenger']"></fa-icon>
						</button>
					</mat-form-field>
				</div>

				<div class="container">
					<mat-form-field appearance="outline">
						<mat-label>Color de Texto (Link)</mat-label>
						<input matInput type="color" formControlName="linkTextColor" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Color de Fondo (Link)</mat-label>
						<input matInput type="color" formControlName="linkBackgroundColor" />
					</mat-form-field>
				</div>

				<form autocomplete="off" class="channelForm" [formGroup]="channelForm" (ngSubmit)="addChannel(cf)" #cf="ngForm" novalidate>
					<div class="channel">
						<mat-form-field appearance="outline">
							<mat-label>Canal</mat-label>
							<mat-select formControlName="channel">
								<mat-option *ngFor="let channel of channelList" [value]="channel.id">
									{{ channel.name }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Alias</mat-label>
							<input matInput type="text" formControlName="alias" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Color de Texto</mat-label>
							<input matInput type="color" formControlName="textColor" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Color de Fondo</mat-label>
							<input matInput type="color" formControlName="backgroundColor" />
						</mat-form-field>
					</div>

					<div style="display: flex; justify-content: flex-end">
						<button mat-raised-button color="primary" type="submit"
							[disabled]="!channelForm.valid">Agregar</button>
					</div>
				</form>

				<table mat-table [dataSource]="selectedChannelList" multiTemplateDataRows class="mat-elevation-z8">
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>Nombre</th>
						<td mat-cell *matCellDef="let channel">{{ channel.name }}</td>
					</ng-container>

					<ng-container matColumnDef="alias">
						<th mat-header-cell *matHeaderCellDef>Alias</th>
						<td mat-cell *matCellDef="let channel">{{ channel.alias }}</td>
					</ng-container>

					<ng-container matColumnDef="textColor">
						<th mat-header-cell *matHeaderCellDef>Color de Texto</th>
						<td mat-cell *matCellDef="let channel">
							<div [style.background-color]="channel.color"
								style="height: 12px; width: 12px; border-style: solid; border-width: 1px; border-color: black;">
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="backgroundColor">
						<th mat-header-cell *matHeaderCellDef>Color de Fondo</th>
						<td mat-cell *matCellDef="let channel">
							<div [style.background-color]="channel.backgroundColor"
								style="height: 12px; width: 12px; border-style: solid; border-width: 1px; border-color: black;">
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="preview">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let channel">
							<mat-button-toggle-group>
								<mat-button-toggle style="width: 150px;" [value]="channel.id"
									[style.background-color]="channel.backgroundColor" [style.color]="channel.color">
									{{channel.alias !== null ? channel.alias : channel.name}}
								</mat-button-toggle>
							</mat-button-toggle-group>
						</td>
					</ng-container>

					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let channel">
							<button mat-icon-button (click)="removeChannel(channel)">
								<mat-icon>delete_outline</mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>

				<mat-divider></mat-divider>

				<div class="email" style="display: flex; justify-content: space-between">
					<button mat-raised-button color="primary" [disabled]="!eventForm.valid">Guardar</button>
				</div>
			</form>
		</mtx-loader>
	</mat-card>
</div>
