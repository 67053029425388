import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import AgoraRTM, { RtmClient } from 'agora-rtm-sdk';
import { TokenIds } from 'src/app/enums/token-ids.enum';
import { EventMessageDto } from 'src/app/models/event-message-dto';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { AuthService } from 'src/app/services/auth-service.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { EventsService } from 'src/app/services/events.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

import { AudienceEmbeddableCodeComponent } from '../audience-embeddable-code/audience-embeddable-code.component';
import { AudienceShareableCodeComponent } from '../audience-shareable-code/audience-shareable-code.component';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class EventsComponent extends BaseComponent implements OnInit {
	displayedColumns: string[] = [
		'live',
		'date',
		// 'hour',
		'company',
		// 'client',
		'name',
		'channels',
	];
	list: LiveInteractiveAudioStreamingEventDto[] = [];
	listLength!: number;
	page!: number;
	pageSize!: number;
	expandedEvent!: LiveInteractiveAudioStreamingEventDto | null;
	isChecked!: boolean;

	isActiveFilter!: boolean;
	activeStates!: State[];

	isLiveFilter!: boolean;
	liveStates!: State[];

	rtmClient!: RtmClient;
	rtmUserId!: string;
	rtmToken!: string;

	@ViewChild(MatTable) table!: MatTable<LiveInteractiveAudioStreamingEventDto>;

	constructor(
		protected override activatedRoute: ActivatedRoute,
		protected override logService: LogService,
		protected override authService: AuthService,
		protected override companiesService: CompaniesService,
		private titleService: Title,
		private router: Router,
		private dialog: MtxDialog,
		private snackBar: MatSnackBar,
		private eventsService: EventsService,
		private auth: AngularFireAuth
	) {
		super(activatedRoute, logService, authService, companiesService);

		this.titleService.setTitle(`${environment.title} | Eventos`);

		this.activeStates = [
			{
				name: 'Sí',
				checked: true,
			},
			{
				name: 'No',
				checked: false,
			},
		];

		this.liveStates = [
			{
				name: 'Sí',
				checked: true,
			},
			{
				name: 'No',
				checked: true,
			},
		];

		this.isActiveFilter = false;
		this.isLiveFilter = true;

		this.page = 0;
		this.pageSize = environment.paging.size;

		this.loading = false;
	}

	override async ngOnInit(): Promise<void> {
		try {
			await super.ngOnInit();

			await this.getEvents();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getEvents(): Promise<void> {
		try {
			this.loading = true;

			let isActive: boolean | undefined = undefined;
			let isLive: boolean | undefined = undefined;

			if (this.some('active')) {
				isActive = this.activeStates.filter(s => s.checked)[0].name === 'Sí';
			} else {
				isActive = undefined;
			}

			if (this.some('live')) {
				isLive = this.liveStates.filter(s => s.checked)[0].name === 'Sí';
			} else {
				isLive = undefined;
			}

			const events = await this.eventsService.getEventsBy(this.selectedCompany?.id, isActive, isLive, this.page, this.pageSize);
			this.list = events as LiveInteractiveAudioStreamingEventDto[];
			this.listLength = await this.eventsService.getEventsByLength(this.selectedCompany?.id, isActive, isLive);

			this.table.renderRows();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async pageChanged(pageEvent: PageEvent): Promise<void> {
		try {
			this.page = pageEvent.pageIndex;
			this.pageSize = pageEvent.pageSize;

			await this.getEvents();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	onToggleGroupChange(event: MatButtonToggleChange): void {
		try {
			event.source.value = null;
			this.isChecked = false;
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	goToUpdate(event: LiveInteractiveAudioStreamingEventDto): void {
		try {
			if (!event.isActive) {
				return;
			}

			this.router.navigate([`/events/${event.id}`]);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	goToHost(event: LiveInteractiveAudioStreamingEventDto): void {
		try {
			if (!event.isActive) {
				return;
			}

			this.router.navigate([`/events/${event.id}/host`]);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	goToAudience(event: LiveInteractiveAudioStreamingEventDto): void {
		try {
			if (!event.isActive) {
				return;
			}

			this.router.navigate([`/events/${event.id}/audience`]);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	goToAudienceEmbeddable(event: LiveInteractiveAudioStreamingEventDto): void {
		try {
			if (!event.isActive) {
				return;
			}

			this.router.navigate([`/events/${event.id}/audience/embeddable`]);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	goToAttendees(event: LiveInteractiveAudioStreamingEventDto): void {
		try {
			if (!event.isActive) {
				return;
			}

			this.router.navigate([`/events/${event.id}/attendees`]);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	getEmbeddableCode(event: LiveInteractiveAudioStreamingEventDto): void {
		try {
			if (!event.isActive) {
				return;
			}

			this.dialog.originalOpen(AudienceEmbeddableCodeComponent, {
				width: '550px',
				data: event,
			});
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	getShareableCode(event: LiveInteractiveAudioStreamingEventDto): void {
		try {
			if (!event.isActive) {
				return;
			}

			this.dialog.originalOpen(AudienceShareableCodeComponent, {
				width: '550px',
				data: event,
			});
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	toggleLiveState(event: LiveInteractiveAudioStreamingEventDto): void {
		if (!event.isActive) {
			return;
		}

		this.dialog.open({
			title: `¿Deseas cambiar el estado de la sesión a ${event.isLive ? 'cerrada' : 'abierta'}?`,
			description: event.isLive
				? 'Se cerrará la sesión y se forzará la desconexión de los participantes. Posteriormente ningún participante podrá conectarse.'
				: 'Se abrirá la sesión para poder transmitir. No se podrá actualizar la información.',
			buttons: [
				{
					text: 'No',
					color: 'primary',
					onClick: () => {
						// do nothing
					},
				},
				{
					focusInitial: true,
					color: 'accent',
					text: 'Sí',
					onClick: async () => {
						try {
							this.loading = true;

							event.isLive = !event.isLive;
							await this.eventsService.updateEvent(event);
							await this.notifyActiveLiveStateChange('Live', event.id, event.isLive);

							this.snackBar.open('¡Éxito! El evento fue actualizado', undefined, {
								verticalPosition: 'top',
								duration: 1000,
								panelClass: 'success',
							});
						} catch (e) {
							this.snackBar.open('¡Error! El evento no fue actualizado', undefined, {
								duration: 1000,
								panelClass: 'danger',
							});

							this.logService.logError(e as Error);
						} finally {
							this.loading = false;
						}
					},
				},
			],
		});
	}

	toggleActiveState(event: LiveInteractiveAudioStreamingEventDto): void {
		if (event.isLive) {
			return;
		}

		this.dialog.open({
			title: `¿Deseas cambiar el estado a ${event.isActive ? 'inactivo' : 'activo'}?`,
			description: event.isActive
				? 'Se desactivará el evento y se cerrará toda actividad.'
				: 'Se activará el evento (puede ser actualizado y se puede transmitir).',
			buttons: [
				{
					text: 'No',
					color: 'primary',
					onClick: () => {
						// do nothing
					},
				},
				{
					focusInitial: true,
					color: 'accent',
					text: 'Sí',
					onClick: async () => {
						try {
							this.loading = true;

							event.isActive = !event.isActive;
							event.isLive = false;

							await this.eventsService.updateEvent(event);
							await this.notifyActiveLiveStateChange('Active', event.id, event.isActive);

							this.snackBar.open('¡Éxito! El evento fue actualizado', undefined, {
								verticalPosition: 'top',
								duration: 1000,
								panelClass: 'success',
							});
						} catch (e) {
							this.snackBar.open('¡Error! El evento no fue actualizado', undefined, {
								duration: 1000,
								panelClass: 'danger',
							});

							this.logService.logError(e as Error);
						} finally {
							this.loading = false;
						}
					},
				},
			],
		});
	}

	async notifyActiveLiveStateChange(state: string, eventId: string, isLive: boolean): Promise<void> {
		try {
			if (state !== 'Active' && state !== 'Live') {
				return;
			}

			if (this.rtmUserId === undefined) {
				this.rtmUserId = await this.authService.getUserId();
			}

			if (this.rtmToken === undefined) {
				this.rtmToken = (await this.eventsService.getHostToken(eventId, TokenIds.RTM, this.rtmUserId)).rtm;
			}

			this.rtmClient = AgoraRTM.createInstance(environment.agoraId);
			await this.rtmClient.login({
				uid: this.rtmUserId,
				token: this.rtmToken,
			});

			const eventMessage = new EventMessageDto();
			eventMessage.date = new Date().toISOString();
			eventMessage.event = `${state}StateChanged`;
			eventMessage.args = isLive;

			const rtmChannel = await this.rtmClient.createChannel(eventId);
			await rtmChannel.join();
			await rtmChannel.sendMessage({
				text: JSON.stringify(eventMessage),
			});

			await rtmChannel.leave();
			await this.rtmClient.logout();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	every(state: string): void {
		try {
			if (state === 'active') {
				this.isActiveFilter = this.activeStates != null && this.activeStates.every(t => t.checked);
			} else if (state === 'live') {
				this.isLiveFilter = this.liveStates != null && this.liveStates.every(t => t.checked);
			}

			this.getEvents();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	some(state: string): boolean {
		try {
			if (state === 'active') {
				if (this.activeStates == null) {
					return false;
				}

				return this.activeStates.filter(t => t.checked).length > 0 && !this.isActiveFilter;
			} else if (state === 'live') {
				if (this.liveStates == null) {
					return false;
				}

				return this.liveStates.filter(t => t.checked).length > 0 && !this.isLiveFilter;
			}

			return true;
		} catch (e) {
			this.logService.logError(e as Error);

			return false;
		}
	}

	toggleAll(checked: boolean, state: string): void {
		try {
			if (state === 'active') {
				this.isActiveFilter = checked;

				if (this.activeStates == null) {
					return;
				}

				this.activeStates.forEach(t => (t.checked = checked));
			} else if (state === 'live') {
				this.isLiveFilter = checked;

				if (this.liveStates == null) {
					return;
				}

				this.liveStates.forEach(t => (t.checked = checked));
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async onSelectedCompanyChanged(): Promise<void> {
		if (this.selectedCompany != undefined) {
			localStorage.setItem('companyId', this.selectedCompany.id);
		}

		await this.getEvents();
	}
}

export interface State {
	name: string;
	checked: boolean;
}
