import { MessageDto } from './message-dto';

export class EventMessageDto extends MessageDto {
	event!: string;
	args!: unknown;

	constructor() {
		super();

		this.type = 'EventMessageDto';
	}
}
