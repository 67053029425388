import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { EventsService } from 'src/app/services/events.service';
import { LicenseService } from 'src/app/services/license.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-audience',
	templateUrl: './audience.component.html',
	styleUrls: ['./audience.component.scss'],
})
export class AudienceComponent implements OnInit {
	eventId!: string;
	event!: LiveInteractiveAudioStreamingEventDto;

	loading!: boolean;

	headerText!: string;
	headerTextColor!: string;
	headerBackgroundColor!: string;
	headerImageUrl!: string;
	supportLink!: string;
	programLink!: string;
	linkTextColor!: string;
	linkBackgroundColor!: string;
	footerImageUrl!: string;
	footerImageLink!: string;
	footerBackgroundColor!: string;
	footerBackgroundColor2!: string;

	downlinkNetworkQuality!: number;
	uplinkNetworkQuality!: number;
	networkQualityIcon!: string;
	networkQualityIconColor!: string;

	constructor(
		private titleService: Title,
		private route: ActivatedRoute,
		private eventsService: EventsService,
		private logService: LogService,
		private licenseService: LicenseService
	) {
		// do nothing
	}

	async ngOnInit(): Promise<void> {
		try {
			this.networkQualityIcon = 'network_check';
			this.networkQualityIconColor = '#BDC3C7';

			this.eventId = this.route.snapshot.paramMap.get('eventId') ?? '';
			await this.getEvent(this.eventId);
			await this.licenseService.checkUserLicense();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getEvent(eventId: string): Promise<void> {
		try {
			this.loading = true;

			this.event = await this.eventsService.getEvent(eventId);

			this.titleService.setTitle(`${environment.title} | Audiencia - ${this.event.name}`);

			this.headerText = this.event.headerText;
			this.headerTextColor = this.event.headerTextColor;
			this.headerBackgroundColor = this.event.headerBackgroundColor;
			this.headerImageUrl = this.event.headerImageUrl;

			this.supportLink = this.event.supportLink;
			this.programLink = this.event.programLink;
			this.linkTextColor = this.event.linkTextColor;
			this.linkBackgroundColor = this.event.linkBackgroundColor;

			this.footerImageUrl = this.event.footerImageUrl;
			this.footerImageLink = this.event.footerImageLink;
			this.footerBackgroundColor = this.event.footerBackgroundColor;
			this.footerBackgroundColor2 = this.convertHex(this.event.footerBackgroundColor, 0.1);
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	onDownlinkNetworkQualityChanged(networkQuality: number): void {
		this.downlinkNetworkQuality = networkQuality;

		switch (networkQuality) {
			case 0:
				this.networkQualityIcon = 'network_check';
				this.networkQualityIconColor = '#BDC3C7';
				break;
			// case 1:
			// 	this.networkQualityIcon = 'wifi';
			// 	this.networkQualityIconColor = '#27AE60';
			// 	break;
			case 1:
			case 2:
				this.networkQualityIcon = 'wifi';
				this.networkQualityIconColor = '#43A047';
				break;
			case 3:
				this.networkQualityIcon = 'wifi_2_bar';
				this.networkQualityIconColor = '#FDD835';
				break;
			case 4:
			case 5:
				this.networkQualityIcon = 'wifi_1_bar';
				this.networkQualityIconColor = '#FB8C00';
				break;
			// case 5:
			// 	this.networkQualityIcon = 'wifi_1_bar';
			// 	this.networkQualityIconColor = '#D35400';
			// 	break;
			case 6:
			default:
				this.networkQualityIcon = 'wifi_off';
				this.networkQualityIconColor = '#E53935';
		}
	}

	onUplinkNetworkQualityChanged(networkQuality: number): void {
		this.uplinkNetworkQuality = networkQuality;
	}

	convertHex(hexCode: string, opacity = 1) {
		let hex = hexCode.replace('#', '');

		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}

		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);

		/* Backward compatibility for whole number based opacity values. */
		if (opacity > 1 && opacity <= 100) {
			opacity = opacity / 100;
		}

		return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
	}
}
