import { BaseDto } from './base-dto';

export class MessageDto extends BaseDto {
	date!: string;

	constructor() {
		super();

		this.type = 'MessageDto';
	}
}
