import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class IDService {
	constructor() {
		// do nothing
	}

	getRandomId(): number {
		return Date.now();
	}
}
