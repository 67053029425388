<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<mat-toolbar-row>
			<span>Canales</span>
			<span class="spacer" style="flex: 1 1 auto"></span>
			<button mat-icon-button routerLink="/channels/new" color="accent">
				<mat-icon>add</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>
	<div>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<table mat-table [dataSource]="list" multiTemplateDataRows class="mat-elevation-z8">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Nombre</th>
					<td mat-cell *matCellDef="let channel">{{ channel.name }}</td>
				</ng-container>

				<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let channel" [attr.colspan]="displayedColumns.length">
						<div class="channel-detail" [@detailExpand]="channel == expandedChannel ? 'expanded' : 'collapsed'">
							<mat-button-toggle-group (change)="onToggleGroupChange($event)">
								<mat-button-toggle mat-icon-button (click)="goToUpdate(channel)" [checked]="isChecked">
									<mat-icon>edit</mat-icon>
								</mat-button-toggle>
							</mat-button-toggle-group>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"
					[class.example-expanded-row]="expandedChannel === row"
					(click)="expandedChannel = expandedChannel === row ? null : row"
				></tr>
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
			</table>
			<mat-paginator
				class="mat-elevation-z8"
				[length]="listLength"
				[pageSize]="pageSize"
				(page)="pageChanged($event)">
			</mat-paginator>
		</mtx-loader>
	</div>
</div>
