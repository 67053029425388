import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyDto } from 'src/app/models/company-dto';
import { AuthService } from 'src/app/services/auth-service.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { LogService } from 'src/app/services/log.service';

@Component({
	selector: 'app-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
	isAdmin!: boolean;
	defaultCompanyId!: string;
	defaultCompany!: CompanyDto;

	companyList!: CompanyDto[];
	selectedCompany!: CompanyDto | undefined;

	loading!: boolean;

	constructor(
		protected activatedRoute: ActivatedRoute,
		protected logService: LogService,
		protected authService: AuthService,
		protected companiesService: CompaniesService
	) {
		// do nothing
	}

	async ngOnInit(): Promise<void> {
		try {
			this.isAdmin = await this.authService.getUserClaim('admin');
			this.defaultCompanyId = await this.authService.getUserClaim('co');
			this.defaultCompany = new CompanyDto();
			this.defaultCompany.id = this.defaultCompanyId;

			await this.getCompanies();

			if (this.isAdmin && this.activatedRoute.snapshot.routeConfig?.component?.name === 'EventsComponent') {
				const all = new CompanyDto();
				all.id = '';
				all.name = 'Todas';

				this.companyList.unshift(all);

				const savedCompanyId = localStorage.getItem('companyId');

				if (savedCompanyId != undefined) {
					this.selectedCompany = this.companyList.find(c => c.id === savedCompanyId);
					localStorage.setItem('companyId', savedCompanyId);
				} else if (this.defaultCompanyId != undefined) {
					this.selectedCompany = this.companyList.find(c => c.id === this.defaultCompanyId);
					localStorage.setItem('companyId', this.defaultCompanyId);
				}
			} else if (this.defaultCompanyId !== undefined) {
				this.selectedCompany = this.companyList?.find(company => company.id === this.defaultCompanyId);
			}
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			// do nothing
		}
	}

	async getCompanies(): Promise<void> {
		try {
			this.loading = true;

			this.companyList = await this.companiesService.getAllCompanies();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}
}
