import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendeeDto } from 'src/app/models/attendee-dto';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { AttendeesService } from 'src/app/services/attendees.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { EventsService } from 'src/app/services/events.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-attendees',
	templateUrl: './attendees.component.html',
	styleUrls: ['./attendees.component.scss'],
})
export class AttendeesComponent extends BaseComponent implements OnInit {
	override loading!: boolean;
	displayedColumns: string[] = ['attendeeId', 'country', 'city', 'ip', 'deviceVendor', 'deviceModel', 'deviceType', 'platform', 'browser', 'renderingEngine'];

	eventId!: string;
	eventList!: LiveInteractiveAudioStreamingEventDto[];
	selectedEvent!: LiveInteractiveAudioStreamingEventDto | undefined;

	list: AttendeeDto[] = [];
	listLength!: number;
	page!: number;
	pageSize!: number;

	@ViewChild(MatTable) table!: MatTable<AttendeeDto>;

	constructor(
		protected override activatedRoute: ActivatedRoute,
		protected override logService: LogService,
		protected override authService: AuthService,
		protected override companiesService: CompaniesService,
		private titleService: Title,
		private router: Router,
		private eventsService: EventsService,
		private attendeesService: AttendeesService
	) {
		super(activatedRoute, logService, authService, companiesService);

		this.titleService.setTitle(`${environment.title} | Asistentes`);

		this.page = 0;
		this.pageSize = environment.paging.size;

		this.loading = false;
	}

	override async ngOnInit(): Promise<void> {
		try {
			await super.ngOnInit();

			this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') ?? '';

			if (this.eventId !== '') {
				this.selectedEvent = await this.eventsService.getEvent(this.eventId);

				if (this.selectedEvent) {
					this.selectedCompany = this.companyList.filter(x => x.id === this.selectedEvent?.company.id)[0];

					const events = await this.eventsService.getEventsBy(this.selectedEvent.company.id, undefined, undefined, 0, 50);
					this.eventList = events as LiveInteractiveAudioStreamingEventDto[];
					this.selectedEvent = this.eventList.filter(x => x.id === this.eventId)[0];
				}

				await this.getAttendees();
			} else {
				const events = await this.eventsService.getEventsBy(this.selectedCompany?.id, undefined, undefined, 0, 50);
				this.eventList = events as LiveInteractiveAudioStreamingEventDto[];
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getAttendees(): Promise<void> {
		try {
			this.loading = true;

			const attendees = await this.attendeesService.getAttendeesBy(this.eventId, this.page, this.pageSize);
			this.list = attendees as AttendeeDto[];
			this.listLength = await this.attendeesService.getAttendeesByLength(this.eventId);

			this.table.renderRows();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async pageChanged(pageEvent: PageEvent): Promise<void> {
		try {
			this.page = pageEvent.pageIndex;
			this.pageSize = pageEvent.pageSize;

			await this.getAttendees();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async onSelectedCompanyChanged(): Promise<void> {
		try {
			this.selectedEvent = undefined;
			this.list = [];
			this.table.renderRows();

			const events = await this.eventsService.getEventsBy(this.selectedCompany?.id, undefined, undefined, 0, 50);
			this.eventList = events as LiveInteractiveAudioStreamingEventDto[];
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async onSelectedEventChanged(): Promise<void> {
		try {
			this.eventId = this.selectedEvent?.id ?? '';
			await this.getAttendees();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}
}
