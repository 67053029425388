<mat-sidenav-container class="sidenav-container" >
	<mat-sidenav #drawer class="sidenav" fixedInViewport="true" autoFocus="true" mode="over">
		<mat-toolbar>Menu</mat-toolbar>
		<mat-nav-list>
			<a *ngIf="isSignedIn" mat-list-item routerLink="/events" (click)="drawer.close();">Eventos</a>
			<a *ngIf="isSignedIn" mat-list-item routerLink="/streams" (click)="drawer.close();">Trasmisiones</a>
			<a *ngIf="isSignedIn" mat-list-item routerLink="/attendees" (click)="drawer.close();">Asistentes</a>
			<a *ngIf="isSignedIn && isAdmin" mat-list-item routerLink="/channels" (click)="drawer.close();">Canales</a>
			<a *ngIf="isSignedIn && isAdmin" mat-list-item routerLink="/companies" (click)="drawer.close();">Compañías</a>
			<a *ngIf="isSignedIn && isAdmin" mat-list-item routerLink="/users" (click)="drawer.close();">Usuarios</a>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-toolbar color="primary">
			<button *ngIf="isSignedIn" mat-icon-button aria-label="Open sidenav" (click)="drawer.open();">
				<mat-icon>menu</mat-icon>
			  </button>
			<!-- <span id="brand" routerLink="/events">{{title}}</span> -->
			<span id="brand" routerLink="/events" style="display:flex; flex-direction:column; justify-content:center;">
				<img src="assets/Interwise.png" alt="logo" width="150">
			</span>
			<span class="spacer" style="flex: 1 1 auto"></span>
			<!-- <ngx-auth-firebaseui-avatar
				layout="simple"
				[canDeleteAccount]="false"
				[canEditAccount]="false"
				[canViewAccount]="false"
				textSignOut="Cerrar Sesión"
				(onSignOut)="onSignOut()"
			></ngx-auth-firebaseui-avatar> -->

			<span *ngIf="auth.user | async as user">{{user.displayName}}</span>
			<button mat-icon-button *ngIf="auth.user | async as user; else showLogin" (click)="onSignOut()">
				<mat-icon>logout</mat-icon>
			</button>

			
			<ng-template #showLogin>
				<button mat-icon-button>
					<mat-icon>login</mat-icon>
				</button>
			</ng-template>
		</mat-toolbar>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
