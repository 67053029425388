<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<span><a class="title" routerLink="/users">Usuarios</a></span>
		<span style="margin-top: 10px;"><mat-icon>chevron_right</mat-icon></span>
		<span>Usuario</span>
		<span class="spacer" style="flex: 1 1 auto"></span>
	</mat-toolbar>
	<mat-card>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<form autocomplete="off" class="userForm" [formGroup]="userForm" (ngSubmit)="saveUser(uf)" #uf="ngForm" novalidate>
				<div class="user">
					<mat-form-field appearance="outline">
						<mat-label>Nombre</mat-label>
						<input matInput type="text" formControlName="displayName" />
					</mat-form-field>
				</div>

				<div>
					<mat-form-field appearance="outline">
						<mat-label>Correo Electrónico</mat-label>
						<input matInput type="text" formControlName="email" />
					</mat-form-field>
				</div>

				<div>
					<mat-form-field appearance="outline">
						<mat-label>Compañía</mat-label>
						<mat-select formControlName="company" [(ngModel)]="selectedCompany">
							<mat-option value=""></mat-option>
							<mat-option *ngFor="let company of companyList" [value]="company">{{company.name}}</mat-option>
						</mat-select>
					  </mat-form-field>
				</div>

				<div>
					<mat-form-field appearance="outline">
						<mat-label>Rol</mat-label>
						<mat-select formControlName="role" [(ngModel)]="selectedRole">
							<mat-option value=""></mat-option>
							<mat-option *ngFor="let role of roleList" [value]="role">{{role}}</mat-option>
						</mat-select>
					  </mat-form-field>
				</div>

				<div style="display: flex; justify-content: flex-end">
					<button mat-raised-button color="primary" type="submit" [disabled]="!userForm.valid">Guardar</button>
				</div>
			</form>
		</mtx-loader>
	</mat-card>
</div>
