import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { LogService } from 'src/app/services/log.service';

@Component({
	selector: 'app-audience-embeddable-code',
	templateUrl: './audience-embeddable-code.component.html',
	styleUrls: ['./audience-embeddable-code.component.scss'],
})
export class AudienceEmbeddableCodeComponent {
	code!: string;

	constructor(
		public dialogRef: MatDialogRef<AudienceEmbeddableCodeComponent>,
		@Inject(MAT_DIALOG_DATA) public data: LiveInteractiveAudioStreamingEventDto,
		private router: Router,
		private clipboard: Clipboard,
		private logService: LogService
	) {
		this.code = `<embed type="text/html" src="${window.location.href}/${data.id}/audience/embeddable" height="50" width="${
			60 + (100 * data.channels?.length ?? 0)
		}" />`;
	}

	close(): void {
		this.dialogRef.close();
	}

	copyCode(): void {
		this.clipboard.copy(this.code);
	}

	goToAudienceEmbeddable(): void {
		try {
			const event = this.data as LiveInteractiveAudioStreamingEventDto;
			if (!event.isActive) {
				return;
			}

			this.router.navigate([`/events/${event.id}/audience/embeddable`]);
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.dialogRef.close();
		}
	}
}
