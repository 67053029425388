<h1 mat-dialog-title>Acuerdo de Licencia</h1>
<div mat-dialog-content>
  <p style="text-align: justify;">Al aceptar este acuerdo el administrador del evento o sesión de “INTERWISE” reconoce que se
    trata de una obra protegida por el derecho de autor cuya propiedad intelectual y marca
    pertenecen a terceros y sobre la que se le concede autorización de uso exclusivamente para el
    evento o la sesión que expresamente ha contratado y únicamente por el tiempo determinado en
    que dicho evento o sesión se llevará a cabo, por lo que debe abstenerse de utilizarlo en el futuro
    sin otra autorización previa y por escrito.</p>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button mat-raised-button mat-dialog-close color="primary">Aceptar</button>
</div>