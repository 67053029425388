import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { ILicense } from "../interfaces/license";
import { MtxDialog } from "@ng-matero/extensions/dialog";
import { UserLicenseComponent } from "../components/user-license/user-license.component";
import { OrganizerLicenseComponent } from "../components/organizer-license/organizer-license.component";
import { firstValueFrom } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LicenseService {
	constructor(
    	private auth: AngularFireAuth,
    	private db: AngularFireDatabase,
    	private mtxDialog: MtxDialog
  	) {}

	async checkOrganizerLicense(): Promise<void> {
		const authUser = await this.auth.currentUser;
		const userRef = this.db.object(`users/${authUser?.uid}`);

		const user = await firstValueFrom(userRef.valueChanges());
		const licensedUser = <ILicense>user;
		if (licensedUser == null || licensedUser.licenseAccepted == false) {
			const dialogRef = this.mtxDialog.open({
				showCloseIcon: false,
				hasBackdrop: true,
				disableClose: true,
				width: "600px",
        	}, OrganizerLicenseComponent);

			await firstValueFrom(dialogRef.afterClosed());
			await this.db
					.object(`users/${authUser?.uid}`)
					.set({ licenseAccepted: true });
		}
	}

  	async checkUserLicense(): Promise<void> {
		const licenseAccepted = localStorage.getItem("licenseAccepted");
		
		if (licenseAccepted == null || licenseAccepted == "false") {
			const dialogRef = this.mtxDialog.open({
				showCloseIcon: false,
				hasBackdrop: true,
				disableClose: true,
				width: "600px",
        	}, UserLicenseComponent);

			await firstValueFrom(dialogRef.afterClosed());

			localStorage.setItem("licenseAccepted", "true");
		}
	}
}
