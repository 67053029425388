import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { StreamDto } from 'src/app/models/stream-dto';
import { AuthService } from 'src/app/services/auth-service.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { EventsService } from 'src/app/services/events.service';
import { LogService } from 'src/app/services/log.service';
import { StreamsService } from 'src/app/services/streams.service';
import { environment } from 'src/environments/environment';

import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-streams',
	templateUrl: './streams.component.html',
	styleUrls: ['./streams.component.scss'],
})
export class StreamsComponent extends BaseComponent implements OnInit {
	moment = moment;
	override loading!: boolean;
	displayedColumns: string[] = ['startDate', 'companyName', 'eventName', 'channels', 'audience', 'time', 'totaltime'];

	eventList!: LiveInteractiveAudioStreamingEventDto[];
	selectedEvent!: LiveInteractiveAudioStreamingEventDto | undefined;

	list: StreamDto[] = [];
	listLength!: number;
	page!: number;
	pageSize!: number;

	@ViewChild(MatTable) table!: MatTable<StreamDto>;

	constructor(
		protected override activatedRoute: ActivatedRoute,
		protected override logService: LogService,
		protected override authService: AuthService,
		protected override companiesService: CompaniesService,
		private titleService: Title,
		private eventsService: EventsService,
		private streamsService: StreamsService
	) {
		super(activatedRoute, logService, authService, companiesService);

		this.titleService.setTitle(`${environment.title} | Transmisiones`);

		this.page = 0;
		this.pageSize = environment.paging.size;

		this.loading = false;
	}

	override async ngOnInit(): Promise<void> {
		try {
			await super.ngOnInit();

			if (!this.isAdmin) {
				const events = await this.eventsService.getEventsBy(this.selectedCompany?.id, undefined, undefined, 0, 50);
				this.eventList = events as LiveInteractiveAudioStreamingEventDto[];
			}

			await this.getStreams();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getStreams(): Promise<void> {
		try {
			this.loading = true;

			this.list = await this.streamsService.getStreamsBy(this.selectedCompany?.id, this.selectedEvent?.id, this.page, this.pageSize);
			this.listLength = await this.streamsService.getStreamsByLength(undefined, undefined);

			this.table.renderRows();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async pageChanged(pageEvent: PageEvent): Promise<void> {
		try {
			this.page = pageEvent.pageIndex;
			this.pageSize = pageEvent.pageSize;

			await this.getStreams();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async onSelectedCompanyChanged(): Promise<void> {
		try {
			this.selectedEvent = undefined;
			this.list = [];
			this.table.renderRows();

			const events = await this.eventsService.getEventsBy(this.selectedCompany?.id, undefined, undefined, 0, 50);
			this.eventList = events as LiveInteractiveAudioStreamingEventDto[];

			await this.getStreams();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async onSelectedEventChanged(): Promise<void> {
		try {
			await this.getStreams();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}
}
