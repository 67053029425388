<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<mat-toolbar-row>
			<span>Usuarios</span>
			<span class="spacer" style="flex: 1 1 auto"></span>
			<!-- <button mat-button (click)="syncUsers()" color="accent" *ngIf="!isAnySelected()">
				<mat-icon>filter_list</mat-icon>
				Filtrar
			</button> -->
			<button mat-button routerLink="/users/new" color="accent" *ngIf="!isAnySelected()">
				<mat-icon>add</mat-icon>
				Agregar
			</button>
			<button mat-button (click)="syncUsers()" color="accent" *ngIf="!isAnySelected()">
				<mat-icon>sync</mat-icon>
				Sincronizar
			</button>

			<!-- User Specific -->
			<button mat-button (click)="resetPasswords()" color="accent" *ngIf="isAnySelected()">
				<mat-icon>password</mat-icon>
				Reiniciar contraseña
			</button>
			<button mat-button (click)="disable()" color="accent" *ngIf="isAnySelected() && isAllSelectedEnabled()">
				<mat-icon>block</mat-icon>
				Deshabilitar
			</button>
			<button mat-button (click)="enable()" color="accent" *ngIf="isAnySelected() && isAllSelectedDisabled()">
				<mat-icon>radio_button_unchecked</mat-icon>
				Habilitar
			</button>
			<!-- <button mat-button (click)="syncUsers()" color="accent" *ngIf="isAnySelected()">
				<mat-icon>archive</mat-icon>
				Archivar
			</button>
			<button mat-button (click)="syncUsers()" color="accent" *ngIf="isAnySelected()">
				<mat-icon>unarchive</mat-icon>
				Desarchivar
			</button> -->
		</mat-toolbar-row>
	</mat-toolbar>
	<div>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="$event ? toggleAllRows() : null"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()">
					  	</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(row) : null"
							[checked]="selection.isSelected(row)">
					  	</mat-checkbox>
					</td>
				</ng-container>
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Nombre</th>
					<td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
				</ng-container>
				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef>Correo Electrónico</th>
					<td mat-cell *matCellDef="let user">{{ user.email }}</td>
				</ng-container>
				<ng-container matColumnDef="company">
					<th mat-header-cell *matHeaderCellDef>Compañía</th>
					<td mat-cell *matCellDef="let user">{{ user.company?.name }}</td>
				</ng-container>
				<ng-container matColumnDef="roles">
					<th mat-header-cell *matHeaderCellDef>Rol</th>
					<td mat-cell *matCellDef="let user">
						{{ user.role }}
					</td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef>Estado</th>
					<td mat-cell *matCellDef="let user">
						<span *ngIf="!user.disabled" style="margin-right: 1.25px; padding: 2.5px; font-size: 12px; background-color: #1976d2; color: #fff;">Activo</span>
						<span *ngIf="user.disabled" style="margin-right: 1.25px; padding: 2.5px; font-size: 12px; background-color: #616161; color: #fff;">Inactivo</span>
					</td>
				</ng-container>

				<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let user" [attr.colspan]="displayedColumns.length">
						<div class="user-detail" [@detailExpand]="user == expandedUser ? 'expanded' : 'collapsed'">
							<mat-button-toggle-group (change)="onToggleGroupChange($event)">
								<mat-button-toggle mat-button (click)="goToUpdate(user)" [checked]="isChecked">
									<mat-icon class="matbuttontoggleicon">edit</mat-icon>
									<span>Editar</span>
								</mat-button-toggle>
							</mat-button-toggle-group>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"
					[class.example-expanded-row]="expandedUser === row"
					(click)="expandedUser = expandedUser === row ? null : row"
				></tr>
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
			</table>
			<mat-paginator
				class="mat-elevation-z8"
				[length]="dataSourceLength"
				[pageSize]="pageSize"
				(page)="pageChanged($event)">
			</mat-paginator>
		</mtx-loader>
	</div>
</div>
