<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<mat-toolbar-row>
			<span>Transmisiones</span>
			<span class="spacer" style="flex: 1 1 auto"></span>
			<button mat-icon-button class="action" (click)="filters.expanded ? filters.close() : filters.open()" color="accent">
				<mat-icon>filter_list</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>
	<mat-expansion-panel class="mat-elevation-z8 filtersContainer" #filters [style.margin-bottom]="filters.expanded? '16px' : '0px'">
		<section class="filters">
			<mat-form-field appearance="fill" style="margin-bottom:-16px;">
				<mat-label>Compañía</mat-label>
				<mat-select [(ngModel)]="selectedCompany" (ngModelChange)="onSelectedCompanyChanged()" [disabled]="!isAdmin">
					<mat-option *ngFor="let company of companyList" [value]="company">
						{{ company.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</section>
		<section class="filters">
			<mat-form-field appearance="fill" style="margin-bottom:-16px;">
				<mat-label>Evento</mat-label>
				<mat-select [(ngModel)]="selectedEvent" (ngModelChange)="onSelectedEventChanged()">
					<mat-option *ngFor="let event of eventList" [value]="event">
						{{ event.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</section>
	</mat-expansion-panel>
	<div>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<table mat-table [dataSource]="list" multiTemplateDataRows class="mat-elevation-z8">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef>Id</th>
					<td mat-cell *matCellDef="let stream">{{ stream.id }}</td>
				</ng-container>

				<ng-container matColumnDef="companyId">
					<th mat-header-cell *matHeaderCellDef>Id Compañía</th>
					<td mat-cell *matCellDef="let stream">{{ stream.companyId }}</td>
				</ng-container>

				<ng-container matColumnDef="companyName">
					<th mat-header-cell *matHeaderCellDef>Compañía</th>
					<td mat-cell *matCellDef="let stream">{{ stream.companyName }}</td>
				</ng-container>

				<ng-container matColumnDef="eventId">
					<th mat-header-cell *matHeaderCellDef>Id Evento</th>
					<td mat-cell *matCellDef="let stream">{{ stream.eventId }}</td>
				</ng-container>

				<ng-container matColumnDef="eventName">
					<th mat-header-cell *matHeaderCellDef>Evento</th>
					<td mat-cell *matCellDef="let stream">{{ stream.eventName }}</td>
				</ng-container>

				<ng-container matColumnDef="channels">
					<th mat-header-cell *matHeaderCellDef>Canales</th>
					<td mat-cell *matCellDef="let stream">{{ stream.channelsCount }}</td>
				</ng-container>

				<ng-container matColumnDef="audience">
					<th mat-header-cell *matHeaderCellDef>Audiencia</th>
					<td mat-cell *matCellDef="let stream">{{ stream.attendeesCount }}</td>
				</ng-container>

				<ng-container matColumnDef="startDate">
					<th mat-header-cell *matHeaderCellDef>Fecha y Hora</th>
					<td mat-cell *matCellDef="let stream">{{ stream.fromDate | date: 'dd/MM/yy, hh:mm a'}}</td>
				</ng-container>

				<ng-container matColumnDef="time">
					<th mat-header-cell *matHeaderCellDef>Duración (Minutos)</th>
					<td mat-cell *matCellDef="let stream">{{ moment.duration(moment(stream.toDate).diff(moment(stream.fromDate))).asMinutes() | number:'1.0' }}</td>
				</ng-container>

				<ng-container matColumnDef="totaltime">
					<th mat-header-cell *matHeaderCellDef>Duración Total (Minutos)</th>
					<td mat-cell *matCellDef="let stream">{{ moment.duration(moment(stream.toDate).diff(moment(stream.fromDate))).asMinutes() * stream.attendeesCount | number:'1.0' }}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"
				></tr>
			</table>
			<mat-paginator
				class="mat-elevation-z8"
				[length]="listLength"
				[pageSize]="pageSize"
				(page)="pageChanged($event)">
			</mat-paginator>
		</mtx-loader>
	</div>
</div>
