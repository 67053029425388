<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<mat-toolbar-row>
			<span>Asistentes</span>
			<span class="spacer" style="flex: 1 1 auto"></span>
			<button mat-icon-button class="action" (click)="filters.expanded ? filters.close() : filters.open()" color="accent">
				<mat-icon>filter_list</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>
	<mat-expansion-panel class="mat-elevation-z8 filtersContainer" #filters [style.margin-bottom]="filters.expanded? '16px' : '0px'">
		<section class="filters">
			<mat-form-field appearance="fill" style="margin-bottom:-16px;">
				<mat-label>Compañía</mat-label>
				<mat-select [(ngModel)]="selectedCompany" (ngModelChange)="onSelectedCompanyChanged()" [disabled]="!isAdmin">
					<mat-option *ngFor="let company of companyList" [value]="company">
						{{ company.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</section>
		<section class="filters">
			<mat-form-field appearance="fill" style="margin-bottom:-16px;">
				<mat-label>Evento</mat-label>
				<mat-select [(ngModel)]="selectedEvent" (ngModelChange)="onSelectedEventChanged()">
					<mat-option *ngFor="let event of eventList" [value]="event">
						{{ event.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</section>
	</mat-expansion-panel>
	<div>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<table mat-table [dataSource]="list" multiTemplateDataRows class="mat-elevation-z8">
				<ng-container matColumnDef="attendeeId">
					<th mat-header-cell *matHeaderCellDef>Asistente</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.attendeeId }}</td>
				</ng-container>

				<ng-container matColumnDef="ip">
					<th mat-header-cell *matHeaderCellDef>IP</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.ip }}</td>
				</ng-container>

				<ng-container matColumnDef="continent">
					<th mat-header-cell *matHeaderCellDef>Continente</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.continent }}</td>
				</ng-container>

				<ng-container matColumnDef="country">
					<th mat-header-cell *matHeaderCellDef>País</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.country }}</td>
				</ng-container>

				<ng-container matColumnDef="state">
					<th mat-header-cell *matHeaderCellDef>Estado</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.state }}</td>
				</ng-container>

				<ng-container matColumnDef="city">
					<th mat-header-cell *matHeaderCellDef>Ciudad</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.city }}</td>
				</ng-container>

				<ng-container matColumnDef="isp">
					<th mat-header-cell *matHeaderCellDef>ISP</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.isp | slice:0:15 }}</td>
				</ng-container>

				<ng-container matColumnDef="deviceVendor">
					<th mat-header-cell *matHeaderCellDef>Marca</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.deviceVendor }}</td>
				</ng-container>

				<ng-container matColumnDef="deviceModel">
					<th mat-header-cell *matHeaderCellDef>Modelo</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.deviceModel }}</td>
				</ng-container>

				<ng-container matColumnDef="deviceType">
					<th mat-header-cell *matHeaderCellDef>Tipo</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.deviceType }}</td>
				</ng-container>

				<ng-container matColumnDef="platform">
					<th mat-header-cell *matHeaderCellDef>Plataforma</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.platform }}</td>
				</ng-container>

				<ng-container matColumnDef="browser">
					<th mat-header-cell *matHeaderCellDef>Explorador</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.browser }}</td>
				</ng-container>

				<ng-container matColumnDef="renderingEngine">
					<th mat-header-cell *matHeaderCellDef>Motor</th>
					<td mat-cell *matCellDef="let attendee">{{ attendee.renderingEngine }}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"
				></tr>
			</table>
			<mat-paginator
				class="mat-elevation-z8"
				[length]="listLength"
				[pageSize]="pageSize"
				(page)="pageChanged($event)">
			</mat-paginator>
		</mtx-loader>
	</div>
</div>
