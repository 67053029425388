import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service.service';
import { LicenseService } from 'src/app/services/license.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
	isSignedIn!: boolean;
	isAdmin!: boolean;
	title!: string;

	constructor(private router: Router, private authService: AuthService, public auth: AngularFireAuth, private licenseService: LicenseService) {
		this.title = `${environment.title}${environment.production ? '' : ' (Pruebas)'}`;
	}

	async ngOnInit(): Promise<void> {
		this.auth.onAuthStateChanged(async user => {
			if (user) {
				this.isSignedIn = true;
				this.isAdmin = await this.authService.getUserClaim('admin');

				await this.licenseService.checkOrganizerLicense();	
			} else {
				this.isSignedIn = false;
				this.isAdmin = false;
			}
		});
	}

	async onSignOut(): Promise<void> {
		await this.auth.signOut();
		await this.router.navigateByUrl('/sign-in');
	}
}
