import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-organizer-license',
  templateUrl: './organizer-license.component.html',
  styleUrls: ['./organizer-license.component.scss']
})
export class OrganizerLicenseComponent {
  constructor(
    public dialogRef: MatDialogRef<OrganizerLicenseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}
