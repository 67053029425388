import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { IDService } from './id.service';
import { LogService } from './log.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private auth: AngularFireAuth, private logService: LogService, private idService: IDService) {
		// do nothing
	}

	async isSignedIn(): Promise<boolean> {
		const user = await this.auth.currentUser;
		return user !== null;
	}

	async getUserId(): Promise<string> {
		let userId: string | null = localStorage.getItem('userId');
		let userIdType: string | null = localStorage.getItem('userIdType');

		if (userId === null) {
			const user = await this.auth.currentUser;
			if (user !== null) {
				this.logService.logInformation(`User Id = ${user.uid} (uid)`);

				userId = user.uid;
				userIdType = 'uid';
			} else {
				try {
					const app = await this.auth.app;
					const installations = await app.installations();
					const installationId = await installations.getId();

					this.logService.logInformation(`User Id = ${installationId} (installation id)`);

					userId = installationId;
					userIdType = 'installation id';
				} catch (e) {
					const randomId = await this.idService.getRandomId();

					this.logService.logInformation(`User Id = ${randomId} (random id)`);

					userId = randomId.toString();
					userIdType = 'random id';
				}
			}

			localStorage.setItem('userId', userId);
			localStorage.setItem('userIdType', userIdType);
		} else {
			this.logService.logInformation(`User Id = ${userId} (${userIdType})`);
		}

		return userId;
	}

	async getUserClaim(claim: string): Promise<any> {
		const user = await this.auth.currentUser;
		if (user !== null) {
			const result = await user.getIdTokenResult();
			const value = await result.claims[claim];

			return value;
		}

		return '';
	}

	resetPassword(email: string): Promise<void> {
		return this.auth.sendPasswordResetEmail(email);
	}
}
