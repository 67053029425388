import { BaseDto } from './base-dto';

export class AttendeeDto extends BaseDto {
	attendanceId!: string;
	eventId!: string;
	eventName!: string;
	attendeeId!: string;
	ip!: string;
	continent!: string;
	country!: string;
	countryCode!: string;
	state!: string;
	city!: string;
	latitude!: number;
	longitude!: number;
	isp!: string;
	deviceVendor!: string;
	deviceModel!: string;
	deviceType!: string;
	platform!: string;
	browser!: string;
	renderingEngine!: string;
}
