import { BaseDto } from './base-dto';
import { ClientDto } from './client-dto';
import { CompanyDto } from './company-dto';

export class EventDto extends BaseDto {
	id!: string; //Server Set
	name!: string;
	notes!: string;
	fromDate!: string;
	toDate!: string;
	company!: CompanyDto;
	client!: ClientDto;
	headerText!: string;
	headerTextColor!: string;
	headerBackgroundColor!: string;
	footerBackgroundColor!: string;
	headerImageUrl!: string;

	isActive!: boolean; // Server Set
}
