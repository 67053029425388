<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<span><a class="title" routerLink="/companies">Compañías</a></span>
		<span style="margin-top: 10px;"><mat-icon>chevron_right</mat-icon></span>
		<span>Compañía</span>
		<span class="spacer" style="flex: 1 1 auto"></span>
	</mat-toolbar>
	<mat-card>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<form autocomplete="off" class="companyForm" [formGroup]="companyForm" (ngSubmit)="saveCompany(cf)" #cf="ngForm" novalidate>
				<div class="company">
					<mat-form-field appearance="outline">
						<mat-label>Nombre</mat-label>
						<input matInput type="text" formControlName="name" />
					</mat-form-field>
				</div>

				<div style="display: flex; justify-content: flex-end">
					<button mat-raised-button color="primary" type="submit" [disabled]="!companyForm.valid">Guardar</button>
				</div>
			</form>
		</mtx-loader>
	</mat-card>
</div>
