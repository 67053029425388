import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { CompanyDto } from 'src/app/models/company-dto';
import { AuthService } from 'src/app/services/auth-service.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-companies',
	templateUrl: './companies.component.html',
	styleUrls: ['./companies.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class CompaniesComponent implements OnInit {
	loading!: boolean;
	displayedColumns: string[] = ['name'];

	list: CompanyDto[] = [];
	listLength!: number;
	page!: number;
	pageSize!: number;

	expandedCompany!: CompanyDto | null;

	isChecked!: boolean;

	@ViewChild(MatTable) table!: MatTable<CompanyDto>;

	constructor(
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dialog: MtxDialog,
		private snackBar: MatSnackBar,
		private logService: LogService,
		private companiesService: CompaniesService,
		private authService: AuthService
	) {
		this.titleService.setTitle(`${environment.title} | Compañías`);

		this.page = 0;
		this.pageSize = environment.paging.size;

		this.loading = false;
	}

	async ngOnInit(): Promise<void> {
		try {
			await this.getCompanies();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getCompanies(): Promise<void> {
		try {
			this.loading = true;

			this.list = await this.companiesService.getCompanies(this.page, this.pageSize);
			this.listLength = await this.companiesService.getCompaniesLength();

			this.table.renderRows();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async pageChanged(pageEvent: PageEvent): Promise<void> {
		try {
			this.page = pageEvent.pageIndex;
			this.pageSize = pageEvent.pageSize;

			await this.getCompanies();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	onToggleGroupChange(event: MatButtonToggleChange): void {
		try {
			event.source.value = null;
			this.isChecked = false;
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	goToUpdate(company: CompanyDto): void {
		try {
			this.router.navigate([`/companies/${company.id}`]);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}
}
