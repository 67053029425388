import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PlatformService {
	constructor(public platform: Platform) {
		// do nothing
	}

	getPlatform(): string {
		if (this.platform.ANDROID) {
			return 'android';
		} else if (this.platform.IOS) {
			return 'ios';
		} else {
			return '';
		}
	}

	getBrowser(): string {
		if (this.platform.EDGE) {
			return 'edge';
		} else if (this.platform.FIREFOX) {
			return 'firefox';
		} else if (this.platform.SAFARI) {
			return 'safari';
		} else {
			return '';
		}
	}

	getRenderingEngine(): string {
		if (this.platform.TRIDENT) {
			return 'trident';
		} else if (this.platform.BLINK) {
			return 'blink';
		} else if (this.platform.WEBKIT) {
			return 'webkit';
		} else {
			return '';
		}
	}
}
