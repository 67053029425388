import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { StreamDto } from '../models/stream-dto';

@Injectable({
	providedIn: 'root',
})
export class StreamsService {
	constructor(private httpClient: HttpClient, public auth: AngularFireAuth) {
		// do nothing
	}

	async getStreamsBy(companyId?: string, eventId?: string, page?: number, pageSize?: number): Promise<StreamDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<StreamDto[]>(
				`${environment.apiUrl}/api/streams
				?companyId=${companyId !== undefined ? companyId : ''}
				&eventId=${eventId !== undefined ? eventId : ''}
				&page=${page}
				&pageSize=${pageSize}`,
				{
					headers: headers,
				}
			));
	}

	async getStreamsByLength(companyId?: string, eventId?: string): Promise<number> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<number>(
				`${environment.apiUrl}/api/streams/length
				?companyId=${companyId !== undefined ? companyId : ''}
				&eventId=${eventId !== undefined ? eventId : ''}`,
				{
					headers: headers,
				}
			));
	}
}
