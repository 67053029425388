import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { ChannelDto } from 'src/app/models/channel-dto';
import { ChannelsService } from 'src/app/services/channels.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-add-update-channel',
	templateUrl: './add-update-channel.component.html',
	styleUrls: ['./add-update-channel.component.scss'],
})
export class AddUpdateChannelComponent implements OnInit {
	loading!: boolean;

	channelForm!: FormGroup;

	channelId!: string;
	channel!: ChannelDto;

	constructor(
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private dialog: MtxDialog,
		private snackbar: MatSnackBar,
		private router: Router,
		public titleService: Title,
		private logService: LogService,
		private channelsService: ChannelsService
	) {
		try {
			this.titleService.setTitle(`${environment.title} | Canal`);

			this.loading = false;

			this.channelForm = this.fb.group({
				name: [null, Validators.required],
			});
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;

			this.channelId = this.route.snapshot.paramMap.get('channelId') ?? '';

			if (this.channelId !== '') {
				await this.getChannel(this.channelId);
			}
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async saveChannel(cf: FormGroupDirective): Promise<void> {
		try {
			if (this.channelForm.valid) {
				this.dialog.open({
					title: '¿Deseas guardar los cambios?',
					buttons: [
						{
							text: 'No',
							color: 'primary',
							onClick: () => {
								// do nothing
							},
						},
						{
							focusInitial: true,
							color: 'accent',
							text: 'Sí',
							onClick: async () => {
								try {
									this.loading = true;

									const channel = new ChannelDto();
									channel.name = this.channelForm.get('name')?.value;

									if (this.channelId === '') {
										await this.channelsService.addChannel(channel);
									} else {
										channel.id = this.channelId;
										await this.channelsService.updateChannel(channel);
									}

									this.snackbar
										.open('¡Éxito! El canal fue guardado', undefined, {
											verticalPosition: 'top',
											duration: 1000,
											panelClass: 'success',
										})
										.afterDismissed()
										.subscribe(async () => {
											await this.router.navigateByUrl('/channels');
										});

									this.resetChannelForm(cf);
								} catch (e) {
									this.snackbar.open('¡Error! El evento no fue guardado', undefined, {
										duration: 1000,
										panelClass: 'danger',
									});

									this.logService.logError(e as Error);
								} finally {
									this.loading = false;
								}
							},
						},
					],
				});
			}
		} catch (e) {
			this.snackbar.open('¡Error! El evento no fue guardado', undefined, {
				duration: 1000,
				panelClass: 'danger',
			});

			this.logService.logError(e as Error);
		}
	}

	resetChannelForm(cf: FormGroupDirective): void {
		try {
			cf.resetForm({
				name: null,
			});

			this.channelForm.markAsPristine();
			this.channelForm.markAsUntouched();
			this.channelForm.updateValueAndValidity();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getChannel(channelId: string): Promise<void> {
		try {
			this.loading = true;

			this.channel = await this.channelsService.getChannel(channelId);

			this.channelForm.patchValue({
				name: this.channel.name,
			});
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}
}
