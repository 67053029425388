import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { UserDto } from '../models/user-dto';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	constructor(private httpClient: HttpClient, public auth: AngularFireAuth) {
		// do nothing
	}

	async getUsers(page?: number, pageSize?: number): Promise<UserDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<UserDto[]>(`${environment.apiUrl}/api/users?&page=${page}&pageSize=${pageSize}`, {
				headers: headers,
			}));
	}

	async getUsersLength(): Promise<number> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<number>(`${environment.apiUrl}/api/users/length`, {
				headers: headers,
			}));
	}

	async getUser(UserId: string): Promise<UserDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		if (UserId?.length > 0) {
			return await firstValueFrom(this.httpClient
				.get<UserDto>(`${environment.apiUrl}/api/users/${UserId}`, {
					headers: headers,
				}));
		} else {
			return await firstValueFrom(new Observable<UserDto>(next => {
				next.next(undefined);
				next.complete();
			}));
		}
	}

	async addUser(User: UserDto): Promise<UserDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.post<UserDto>(`${environment.apiUrl}/api/users`, User, {
				headers: headers,
			}));
	}

	async updateUser(user: UserDto): Promise<UserDto> {
		const authUser = await this.auth.currentUser;
		const token = await authUser?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.put<UserDto>(`${environment.apiUrl}/api/users/${user.id}`, user, {
				headers: headers,
			}));
	}

	async syncUsers(): Promise<void> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.post<void>(`${environment.apiUrl}/api/users/sync`, {
				headers: headers,
			}));
	}
}
