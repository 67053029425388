<h1 mat-dialog-title>Código QR</h1>
<div mat-dialog-content>
	<div>
		<mat-form-field appearance="fill">
			<mat-label>Tamaño</mat-label>
			<mat-select [(value)]="selectedSize">
				<mat-option></mat-option>
				<mat-option *ngFor="let size of sizeList" [value]="size">
					{{ size }}
				</mat-option>
			</mat-select>
		  </mat-form-field>
	</div>
	<div class="container">
		<qr-code [value]="url" [size]="selectedSize" errorCorrectionLevel="M"></qr-code>
	</div>
	<div class="containerurl">
		<a href="{{ url }}" target="_blank">{{ url }}</a>
	</div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Cerrar</button>
  <button mat-button color="accent" (click)="goToAudience()">Audiencia</button>
</div>
