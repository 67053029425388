import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { UserDto } from 'src/app/models/user-dto';
import { AuthService } from 'src/app/services/auth-service.service';
import { LogService } from 'src/app/services/log.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class UsersComponent implements OnInit {
	loading!: boolean;
	displayedColumns: string[] = ['select', 'name', 'email', 'company', 'roles', 'status'];

	dataSource = new MatTableDataSource<UserDto>();
	dataSourceLength!: number;
	page!: number;
	pageSize!: number;
	selection = new SelectionModel<UserDto>(true, []);

	expandedUser!: UserDto | null;

	isChecked!: boolean;

	@ViewChild(MatTable) table!: MatTable<UserDto>;

	constructor(
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dialog: MtxDialog,
		private snackBar: MatSnackBar,
		private logService: LogService,
		private usersService: UsersService,
		private authService: AuthService
	) {
		this.titleService.setTitle(`${environment.title} | Usuarios`);

		this.page = 0;
		this.pageSize = environment.paging.size;

		this.loading = false;
	}

	async ngOnInit(): Promise<void> {
		try {
			await this.getUsers();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getUsers(): Promise<void> {
		try {
			this.loading = true;

			this.dataSource.data = await this.usersService.getUsers(this.page, this.pageSize);
			this.dataSourceLength = await this.usersService.getUsersLength();

			this.table.renderRows();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async pageChanged(pageEvent: PageEvent): Promise<void> {
		try {
			this.page = pageEvent.pageIndex;
			this.pageSize = pageEvent.pageSize;

			await this.getUsers();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	onToggleGroupChange(event: MatButtonToggleChange): void {
		try {
			event.source.value = null;
			this.isChecked = false;
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	goToUpdate(user: UserDto): void {
		try {
			this.router.navigate([`/users/${user.id}`]);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async syncUsers(): Promise<void> {
		try {
			this.loading = true;

			await this.usersService.syncUsers();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;

			await this.getUsers();
		}
	}

	toggleAllRows() {
		if (this.isAllSelected()) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	isAnySelected(): boolean {
		const numSelected = this.selection.selected.length;
		return numSelected > 0;
	}

	isAllSelectedDisabled(): boolean {
		const numSelected = this.selection.selected.length;
		const numDisabled = this.selection.selected.filter(user => user.disabled).length;
		return numSelected === numDisabled;
	}

	isAllSelectedEnabled(): boolean {
		const numSelected = this.selection.selected.length;
		const numEnabled = this.selection.selected.filter(user => !user.disabled).length;
		return numSelected === numEnabled;
	}

	enable(): void {
		if (this.selection.selected.length > 0) {
			this.loading = true;

			this.selection.selected.forEach(async user => {
				try {
					user.disabled = false;
					await this.usersService.updateUser(user);
				} catch (e) {
					// do nothing
				} finally {
					this.loading = false;
				}
			});
		}
	}

	disable(): void {
		if (this.selection.selected.length > 0) {
			this.loading = true;

			this.selection.selected.forEach(async user => {
				try {
					user.disabled = true;
					await this.usersService.updateUser(user);
				} catch (e) {
					// do nothing
				} finally {
					this.loading = false;
				}
			});
		}
	}

	resetPasswords(): void {
		if (this.selection.selected.length > 0) {
			this.loading = true;

			this.selection.selected.forEach(async user => {
				try {
					await this.authService.resetPassword(user.email);
				} catch (e) {
					// do nothing
				} finally {
					this.loading = false;
				}
			});
		}
	}
}
