import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LicenseService } from 'src/app/services/license.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
	private _document!: Document;

	constructor(private router: Router, public titleService: Title, private logService: LogService, private licenseService: LicenseService) {
		try {
			this.titleService.setTitle(`${environment.title} | Iniciar Sesión`);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async onSuccess(): Promise<void> {
		await this.licenseService.checkOrganizerLicense();
		await this.router.navigateByUrl('/events');
	}

	async OnError(event: any): Promise<void> {
		// do nothing
	}
}
