import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { LogService } from 'src/app/services/log.service';

@Component({
	selector: 'app-audience-shareable-code',
	templateUrl: './audience-shareable-code.component.html',
	styleUrls: ['./audience-shareable-code.component.scss'],
})
export class AudienceShareableCodeComponent {
	url!: string;
	sizeList!: string[];
	selectedSize!: string;

	constructor(
		public dialogRef: MatDialogRef<AudienceShareableCodeComponent>,
		@Inject(MAT_DIALOG_DATA) public data: LiveInteractiveAudioStreamingEventDto,
		private router: Router,
		private logService: LogService
	) {
		this.url = `${window.location.origin}/events/${data.id}/audience`;
		this.sizeList = ['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000'];
		this.selectedSize = this.sizeList[2];
	}

	close(): void {
		this.dialogRef.close();
	}

	goToAudience(): void {
		try {
			const event = this.data as LiveInteractiveAudioStreamingEventDto;
			if (!event.isActive) {
				return;
			}

			this.router.navigate([`/events/${event.id}/audience`]);
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.dialogRef.close();
		}
	}
}
