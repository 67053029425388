import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { CompanyDto } from 'src/app/models/company-dto';
import { UserDto } from 'src/app/models/user-dto';
import { CompaniesService } from 'src/app/services/companies.service';
import { LogService } from 'src/app/services/log.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-add-update-user',
	templateUrl: './add-update-user.component.html',
	styleUrls: ['./add-update-user.component.scss'],
})
export class AddUpdateUserComponent implements OnInit {
	loading!: boolean;

	userForm!: FormGroup;

	userId!: string;
	user!: UserDto;

	companyList!: CompanyDto[];
	selectedCompany!: CompanyDto;

	roleList!: string[];
	selectedRole!: string;

	constructor(
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private dialog: MtxDialog,
		private snackbar: MatSnackBar,
		private router: Router,
		public titleService: Title,
		private logService: LogService,
		private usersService: UsersService,
		private companiesService: CompaniesService
	) {
		try {
			this.titleService.setTitle(`${environment.title} | Usuario`);

			this.loading = false;

			this.userForm = this.fb.group({
				displayName: [null, Validators.required],
				email: [null, Validators.required],
				company: [null],
				role: [null, Validators.required],
			});

			this.roleList = ['admin', 'user'];
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;

			this.userId = this.route.snapshot.paramMap.get('userId') ?? '';

			await this.getCompanies();

			if (this.userId !== '') {
				await this.getUser(this.userId);
			}
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async saveUser(cf: FormGroupDirective): Promise<void> {
		try {
			if (this.userForm.valid) {
				this.dialog.open({
					title: '¿Deseas guardar los cambios?',
					buttons: [
						{
							text: 'No',
							color: 'primary',
							onClick: () => {
								// do nothing
							},
						},
						{
							focusInitial: true,
							color: 'accent',
							text: 'Sí',
							onClick: async () => {
								try {
									this.loading = true;

									const user = new UserDto();
									user.firebaseId = this.userId === '' ? '' : this.user.firebaseId;
									user.displayName = this.userForm.get('displayName')?.value;
									user.email = this.userForm.get('email')?.value;
									user.company = this.selectedCompany;
									user.role = this.selectedRole;

									if (this.userId === '') {
										await this.usersService.addUser(user);
									} else {
										user.id = this.userId;
										await this.usersService.updateUser(user);
									}

									this.snackbar
										.open('¡Éxito! El usuario fue guardado', undefined, {
											verticalPosition: 'top',
											duration: 1000,
											panelClass: 'success',
										})
										.afterDismissed()
										.subscribe(async () => {
											await this.router.navigateByUrl('/users');
										});

									this.resetUserForm(cf);
								} catch (e) {
									this.snackbar.open('¡Error! El usuario no fue guardado', undefined, {
										duration: 1000,
										panelClass: 'danger',
									});

									this.logService.logError(e as Error);
								} finally {
									this.loading = false;
								}
							},
						},
					],
				});
			}
		} catch (e) {
			this.snackbar.open('¡Error! El evento no fue guardado', undefined, {
				duration: 1000,
				panelClass: 'danger',
			});

			this.logService.logError(e as Error);
		}
	}

	resetUserForm(cf: FormGroupDirective): void {
		try {
			cf.resetForm({
				displayName: null,
				email: null,
				company: null,
				role: null,
			});

			this.userForm.markAsPristine();
			this.userForm.markAsUntouched();
			this.userForm.updateValueAndValidity();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getUser(userId: string): Promise<void> {
		try {
			this.loading = true;

			this.user = await this.usersService.getUser(userId);

			let company: CompanyDto | undefined;
			if (this.user.company !== null) {
				company = this.companyList.find(c => c.id === this.user.company.id);
			}

			this.userForm.patchValue({
				displayName: this.user.displayName,
				email: this.user.email,
				company: company,
				role: this.user.role,
			});
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async getCompanies(): Promise<void> {
		try {
			this.loading = true;

			this.companyList = await this.companiesService.getAllCompanies();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}
}
