import { ChannelDto } from './channel-dto';
import { EventDto } from './event-dto';

export class LiveInteractiveAudioStreamingEventDto extends EventDto {
	channels!: ChannelDto[];
	isVertical!: boolean; //

	hostsNumber!: number; //
	audienceSize!: number; //
	idRequired!: boolean;
	isLive!: boolean;

	override headerBackgroundColor!: string;
	override headerImageUrl!: string;
	override headerTextColor!: string;
	override headerText!: string;

	supportLink!: string;
	programLink!: string;
	linkTextColor!: string;
	linkBackgroundColor!: string;

	footerImageUrl!: string;
	footerImageLink!: string;
	override footerBackgroundColor!: string;

	constructor() {
		super();

		this.type = 'LiveInteractiveAudioStreamingEventDto';
	}
}
