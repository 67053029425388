<div class="grid-container">
	<mat-toolbar style="background-color: transparent">
		<mat-toolbar-row>
			<span>Eventos</span>
			<span class="spacer" style="flex: 1 1 auto"></span>
			<button mat-icon-button class="action" (click)="filters.expanded ? filters.close() : filters.open()" color="accent">
				<mat-icon>filter_list</mat-icon>
			</button>
			<button mat-icon-button routerLink="/events/new" color="accent">
				<mat-icon>add</mat-icon>
			</button>
		</mat-toolbar-row>
	</mat-toolbar>
	<mat-expansion-panel class="mat-elevation-z8 filtersContainer" #filters [style.margin-bottom]="filters.expanded? '16px' : '0px'">
		<section class="filters">
			<mat-form-field appearance="fill" style="margin-bottom:-16px;" color="accent">
				<mat-label>Compañía</mat-label>
				<mat-select [(ngModel)]="selectedCompany" (ngModelChange)="onSelectedCompanyChanged()" [disabled]="!isAdmin">
					<mat-option *ngFor="let company of companyList" [value]="company">
						{{ company.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</section>
		<section class="filters">
			<mat-checkbox class="filter" [checked]="isActiveFilter" [indeterminate]="some('active')" (change)="toggleAll($event.checked, 'active')"> Activos </mat-checkbox>
			<mat-checkbox class="filter" *ngFor="let state of activeStates" [(ngModel)]="state.checked" (ngModelChange)="every('active')" [name]="state.name">
				{{ state.name }}
			</mat-checkbox>
		</section>
		<section class="filters">
			<mat-checkbox class="filter" [checked]="isLiveFilter" [indeterminate]="some('live')" (change)="toggleAll($event.checked, 'live')"> En Vivo </mat-checkbox>
			<mat-checkbox class="filter" *ngFor="let state of liveStates" [(ngModel)]="state.checked" (ngModelChange)="every('live')" [name]="state.name">
				{{ state.name }}
			</mat-checkbox>
		</section>
	</mat-expansion-panel>
	<div>
		<mtx-loader [loading]="loading" type="spinner" color="accent" [hasBackdrop]="true">
			<table mat-table [dataSource]="list" multiTemplateDataRows class="mat-elevation-z8">
				<ng-container matColumnDef="live">
					<th mat-header-cell *matHeaderCellDef></th>
					<td [ngClass]="event.isActive? '' : 'inactive'" mat-cell *matCellDef="let event" style="width: 48px;">
							<mat-icon *ngIf="event.isLive" [style.color]="'#388e3c'">light_mode</mat-icon>
					</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef>Fecha</th>
					<td [ngClass]="event.isActive? '' : 'inactive'" mat-cell *matCellDef="let event">{{ event.fromDate | date: 'MMM dd, YYYY hh:mm a' }}</td>
				</ng-container>

				<ng-container matColumnDef="hour">
					<th mat-header-cell *matHeaderCellDef>Hora</th>
					<td [ngClass]="event.isActive? '' : 'inactive'" mat-cell *matCellDef="let event">{{ event.fromDate | date: 'hh:mm a' }}</td>
				</ng-container>

				<ng-container matColumnDef="company">
					<th mat-header-cell *matHeaderCellDef>Compañía</th>
					<td [ngClass]="event.isActive? '' : 'inactive'" mat-cell *matCellDef="let event">{{ event.company?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="client">
					<th mat-header-cell *matHeaderCellDef>Cliente</th>
					<td [ngClass]="event.isActive? '' : 'inactive'" mat-cell *matCellDef="let event">{{ event.client?.name }}</td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Evento</th>
					<td [ngClass]="event.isActive? '' : 'inactive'" mat-cell *matCellDef="let event">{{ event.name }}</td>
				</ng-container>

				<ng-container matColumnDef="channels">
					<th mat-header-cell *matHeaderCellDef>Canales</th>
					<td [ngClass]="event.isActive? '' : 'inactive'" mat-cell *matCellDef="let event">
						<span
							*ngFor="let channel of event.channels"
							[style.background-color]="channel.backgroundColor"
							class="event-card-content-chip"
							style="margin-right: 1.25px; padding: 2.5px; font-size: 12px"
						>
							<span *ngIf="!channel.alias" [style.color]="channel.color">{{ channel.name }}</span>
							<span *ngIf="channel.alias" [style.color]="channel.color">{{ channel.alias }}</span>
						</span>
					</td>
				</ng-container>

				<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let event" [attr.colspan]="displayedColumns.length">
						<div class="event-detail" [@detailExpand]="event == expandedEvent ? 'expanded' : 'collapsed'">
							<mat-button-toggle-group (change)="onToggleGroupChange($event)">
								<mat-button-toggle mat-icon-button (click)="goToUpdate(event)" [checked]="isChecked" [disabled]="!event.isActive">
									<mat-icon>edit</mat-icon>
								</mat-button-toggle>
								<mat-button-toggle disabled="true" class="mat-button-toggle-separator"></mat-button-toggle>
								<mat-button-toggle mat-icon-button (click)="goToHost(event)" [checked]="isChecked" [disabled]="!event.isActive">
									<mat-icon>mic</mat-icon>
								</mat-button-toggle>
								<mat-button-toggle mat-icon-button (click)="goToAudience(event)" [checked]="isChecked" [disabled]="!event.isActive">
									<mat-icon>headphones</mat-icon>
								</mat-button-toggle>
								<mat-button-toggle mat-icon-button (click)="getEmbeddableCode(event)" [checked]="isChecked" [disabled]="!event.isActive">
									<mat-icon>code</mat-icon>
								</mat-button-toggle>
								<mat-button-toggle mat-icon-button (click)="getShareableCode(event)" [checked]="isChecked" [disabled]="!event.isActive">
									<mat-icon>qr_code</mat-icon>
								</mat-button-toggle>
								<mat-button-toggle disabled="true" class="mat-button-toggle-separator"></mat-button-toggle>
								<mat-button-toggle mat-icon-button (click)="goToAttendees(event)" [checked]="isChecked" [disabled]="!event.isActive">
									<mat-icon>groups</mat-icon>
								</mat-button-toggle>
								<mat-button-toggle disabled="true" class="mat-button-toggle-separator"></mat-button-toggle>
								<mat-button-toggle mat-icon-button [disabled]="!event.isActive" (click)="toggleLiveState(event)">
									<mat-icon *ngIf="!event.isLive">record_voice_over</mat-icon>
									<mat-icon *ngIf="event.isLive">voice_over_off</mat-icon>
								</mat-button-toggle>
								<mat-button-toggle mat-icon-button [disabled]="event.isLive" (click)="toggleActiveState(event)">
									<mat-icon *ngIf="!event.isActive">radio_button_unchecked</mat-icon>
									<mat-icon *ngIf="event.isActive">block</mat-icon>
								</mat-button-toggle>
							</mat-button-toggle-group>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns"
					[class.example-expanded-row]="expandedEvent === row"
					(click)="expandedEvent = expandedEvent === row ? null : row"
				></tr>
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
			</table>
			<mat-paginator
				class="mat-elevation-z8"
				[length]="listLength"
				[pageSize]="pageSize"
				(page)="pageChanged($event)">
			</mat-paginator>
		</mtx-loader>
	</div>
</div>
