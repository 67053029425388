import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IPDto } from '../models/ip-dto';

@Injectable({
	providedIn: 'root',
})
export class IPWhoIsService {
	constructor(private httpClient: HttpClient) {
		// do nothing
	}

	async getIPData(): Promise<IPDto> {
		return await firstValueFrom(this.httpClient.get<IPDto>(`${environment.ipwhois.apiUrl}${environment.ipwhois.apiKey}`));
	}
}
