<div class="grid-container host">
	<mat-toolbar style="background-color: transparent;">
		<span>Anfitrión - {{event.name}}</span>
	</mat-toolbar>
	<mtx-loader
		[loading]="loading"
		[type]="'spinner'"
		color="accent"
		[hasBackdrop]="true"
	>
		<div class="grid-container host content">
			<mat-card>
				<mat-card-header>
					<div mat-card-avatar>
						<mat-icon aria-hidden="false" aria-label="Example home icon">spoke</mat-icon>
					</div>
				</mat-card-header>
				<mat-card-content>
					<mat-form-field appearance="fill">
						<mat-select [disabled]="channelsDisabled" (selectionChange)="onChannelSelected($event)" [(value)]="selectedChannel">
							<mat-option *ngFor="let channel of channelList" [value]="channel.id">
								{{ channel.alias !== null ? channel.alias : channel.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-header>
					<div mat-card-avatar>
						<mat-icon aria-hidden="false" aria-label="Example home icon">mic</mat-icon>
					</div>
				</mat-card-header>
				<mat-card-content>
					<mat-form-field appearance="fill">
						<mat-select [disabled]="micsDisabled" (selectionChange)="onMicSelected($event)" [(value)]="selectedMic">
							<mat-option *ngFor="let mic of micList" [value]="mic.deviceId">
								{{ mic.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-header>
					<div mat-card-avatar>
						<mat-icon aria-hidden="false" aria-label="Example home icon">equalizer</mat-icon>
					</div>
				</mat-card-header>
				<mat-card-content>
					<canvas #canvas width="200" height="50"></canvas>
				</mat-card-content>
			</mat-card>

			<mat-card>
				<mat-card-header>
					<div mat-card-avatar>
						<mat-icon aria-hidden="false" aria-label="Example home icon">people</mat-icon>
					</div>
				</mat-card-header>
				<mat-card-content>
					<div id="users">
						<div id="counter">
							{{rtmChannelMemberCount}}
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</mtx-loader>
</div>
<div class="bottombar">
	<button mat-mini-fab [class]="callClass" color="warn" (click)="toggle()" [disabled]="!event.isActive || !event.isLive" matTooltip="Iniciar o terminar llamada">
		<mat-icon *ngIf="!isCallActive">call</mat-icon>
		<mat-icon *ngIf="isCallActive">call_end</mat-icon>
	</button>

	<!-- <button mat-mini-fab [class]="callClass" color="warn" (click)="toggleStream()" [disabled]="!event.isActive || !event.isLive" matTooltip="Iniciar o terminar llamada">
		<mat-icon *ngIf="!isCallActive">call</mat-icon>
		<mat-icon *ngIf="isCallActive">call_end</mat-icon>
	</button>
	<button mat-mini-fab [class]="micClass" color="warn" (click)="toggleMic()" [disabled]="!event.isActive" matTooltip="Activar o desactivar micrófono">
		<mat-icon *ngIf="!isMicEnabled">mic_off</mat-icon>
		<mat-icon *ngIf="isMicEnabled">mic</mat-icon>
	</button> -->
	<div style="height: 40px; width: 60px;">
		<mat-spinner
			*ngIf="waiting"
			color="accent"
			mode="indeterminate"
			diameter="40">
		</mat-spinner>
	</div>
</div>
