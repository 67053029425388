export const environment = {
	title: 'Interwise',
	production: true,
	staging: false,
	apiUrl: 'https://hermes-2106-api.azurewebsites.net',
	agoraId: 'd2b74e805aa74473aa254e104ee256b6',
	firebase: {
		apiKey: 'AIzaSyBNp5bDEmBz0GgCoNOcJlHJS_6v-uhIMaM',
		authDomain: 'hermes-2106.firebaseapp.com',
		projectId: 'hermes-2106',
		storageBucket: 'hermes-2106.appspot.com',
		messagingSenderId: '730722314704',
		appId: '1:730722314704:web:bd1a4aba138ad6f126f1d5',
		measurementId: 'G-QHVGHFWHWG',
	},
	ipwhois: {
		apiUrl: 'https://ipwhois.pro/?key=',
		apiKey: 'bdHbeha4hpeJlJrd',
	},
	paging: {
		size: 10,
	},
};
