import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ChannelDto } from '../models/channel-dto';

@Injectable({
	providedIn: 'root',
})
export class ChannelsService {
	constructor(private httpClient: HttpClient, public auth: AngularFireAuth) {
		// do nothing
	}

	async getAllChannels(): Promise<ChannelDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<ChannelDto[]>(`${environment.apiUrl}/api/channels`, {
				headers: headers,
			}));
	}

	async getChannels(page?: number, pageSize?: number): Promise<ChannelDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<ChannelDto[]>(`${environment.apiUrl}/api/channels`, {
				headers: headers,
			}));
	}

	async getChannelsLength(): Promise<number> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<number>(`${environment.apiUrl}/api/channels/length`, {
				headers: headers,
			}));
	}

	async getChannel(channelId: string): Promise<ChannelDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		if (channelId?.length > 0) {
			return await firstValueFrom(this.httpClient
				.get<ChannelDto>(`${environment.apiUrl}/api/channels/${channelId}`, {
					headers: headers,
				}));
		} else {
			return await firstValueFrom(new Observable<ChannelDto>(next => {
				next.next(undefined);
				next.complete();
			}));
		}
	}

	async addChannel(channel: ChannelDto): Promise<ChannelDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.post<ChannelDto>(`${environment.apiUrl}/api/channels`, channel, {
				headers: headers,
			}));
	}

	async updateChannel(channel: ChannelDto): Promise<ChannelDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.put<ChannelDto>(`${environment.apiUrl}/api/channels/${channel.id}`, channel, {
				headers: headers,
			}));
	}
}
