import { BaseDto } from './base-dto';

export class ChannelDto extends BaseDto {
	id!: string;
	name!: string;
	alias!: string;
	color!: string;
	backgroundColor!: string;
	isDisabled!: boolean;
	isChecked!: boolean;
}
