import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEsMx from '@angular/common/locales/es-MX';
import { isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MtxAlertModule } from '@ng-matero/extensions/alert';
import { MtxDialogModule } from '@ng-matero/extensions/dialog';
import { MtxLoaderModule } from '@ng-matero/extensions/loader';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { QrCodeModule } from 'ng-qrcode';

import { environment } from '../environments/environment';
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AddUpdateChannelComponent } from './components/add-update-channel/add-update-channel.component';
import { AddUpdateCompanyComponent } from './components/add-update-company/add-update-company.component';
import { AddUpdateEventComponent } from './components/add-update-event/add-update-event.component';
import { AddUpdateUserComponent } from './components/add-update-user/add-update-user.component';
import { AppComponent } from './components/app/app.component';
import { AttendeesComponent } from './components/attendees/attendees.component';
import { AudienceEmbeddableCodeComponent } from './components/audience-embeddable-code/audience-embeddable-code.component';
import { AudienceEmbeddableComponent } from './components/audience-embeddable/audience-embeddable.component';
import { AudienceShareableCodeComponent } from './components/audience-shareable-code/audience-shareable-code.component';
import { AudienceComponent } from './components/audience/audience.component';
import { BaseComponent } from './components/base/base.component';
import { ChannelsComponent } from './components/channels/channels.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventComponent } from './components/event/event.component';
import { EventsComponent } from './components/events/events.component';
import { HostComponent } from './components/host/host.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { StreamsComponent } from './components/streams/streams.component';
import { UsersComponent } from './components/users/users.component';
import { EsMatPaginator } from './controls/es-mat-paginator';
import { UserLicenseComponent } from './components/user-license/user-license.component';
import { OrganizerLicenseComponent } from './components/organizer-license/organizer-license.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
	signInOptions: [
		{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
		},
	],
	tosUrl: '<your-tos-link>',
	privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
	credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
};

registerLocaleData(localeEsMx, 'es-MX');

@NgModule({
	declarations: [
		AppComponent,
		NavigationComponent,
		DashboardComponent,
		EventsComponent,
		EventComponent,
		HostComponent,
		AudienceComponent,
		AudienceEmbeddableComponent,
		AddUpdateEventComponent,
		AudienceEmbeddableCodeComponent,
		SignInComponent,
		ChannelsComponent,
		AddUpdateChannelComponent,
		UsersComponent,
		CompaniesComponent,
		AddUpdateCompanyComponent,
		AddUpdateUserComponent,
		AudienceShareableCodeComponent,
		AttendeesComponent,
		StreamsComponent,
		BaseComponent,
		UserLicenseComponent,
		OrganizerLicenseComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAnalytics(() => getAnalytics()),
		provideAuth(() => getAuth()),
		provideDatabase(() => getDatabase()),
		provideMessaging(() => getMessaging()),
		providePerformance(() => getPerformance()),
		provideRemoteConfig(() => getRemoteConfig()),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		FirebaseUIModule.forRoot(firebaseUiAuthConfig),
		FormsModule,
		MtxAlertModule,
		MtxDialogModule,
		MtxLoaderModule,
		AppMaterialModule,
		BrowserAnimationsModule,
		LayoutModule,
		ReactiveFormsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
		}),
		HttpClientModule,
		MatNativeDateModule,
		MatDatepickerModule,
		ClipboardModule,
		QrCodeModule,
		FontAwesomeModule,
		MtxDatetimepickerModule,
		MtxNativeDatetimeModule, 
	],
	providers: [ScreenTrackingService, UserTrackingService, { provide: LOCALE_ID, useValue: 'es-MX' }, { provide: MatPaginatorIntl, useClass: EsMatPaginator }],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		if (environment.staging || environment.production) {
			// do nothing
		}

		library.addIconPacks(fas, far, fab);
	}
}
