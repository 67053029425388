<h1 mat-dialog-title>Código Incrustable</h1>
<div mat-dialog-content>
  <code id="code">
	  {{code}}
  </code>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Cerrar</button>
  <button mat-button color="accent" (click)="goToAudienceEmbeddable()">Audiencia Incrustable</button>
  <button mat-button color="primary" (click)="copyCode()" cdkFocusInitial>Copiar Código</button>
</div>
