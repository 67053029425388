import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import { AnalyticsEvent } from '../enums/analytics-event.enum';

@Injectable({
	providedIn: 'root',
})
export class LogService {
	constructor(private analytics: AngularFireAnalytics) {
		this.analytics.setAnalyticsCollectionEnabled(true);
	}

	logEvent(event: AnalyticsEvent, eventProperties?: { [key: string]: any } | undefined): void {
		this.analytics.logEvent(event, eventProperties);

		console.info(`%cHermes [INFO]: ${event}`, 'color: #2196f3;');

		if (eventProperties !== undefined) {
			console.table(eventProperties);
		}
	}

	logInformation(message: string): void {
		console.info(`%cHermes [INFORMATION]: ${message}`, 'color: #2196f3');
	}

	logWarning(message: string): void {
		console.warn(`%cHermes [WARNING]: ${message}`, 'color: #f4ab36');
	}

	logError(error: Error): void {
		console.log(`%cHermes [ERROR]: ${error.name} \nMessage: ${error.message} \nStack: ${error.stack}`, 'color: #f44336');
	}
}
