import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { ChannelDto } from 'src/app/models/channel-dto';
import { CompanyDto } from 'src/app/models/company-dto';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { AuthService } from 'src/app/services/auth-service.service';
import { ChannelsService } from 'src/app/services/channels.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { EventsService } from 'src/app/services/events.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

import { BaseComponent } from '../base/base.component';

import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
	parse: {
		dateInput: 'YYYY-MM-DD',
	},
	display: {
		dateInput: 'YYYY-MM-DD',
		monthLabel: 'MMMM',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMM YYYY',
	},
};

@Component({
	selector: 'app-add-update-event',
	templateUrl: './add-update-event.component.html',
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
	styleUrls: ['./add-update-event.component.scss'],
})
export class AddUpdateEventComponent extends BaseComponent implements OnInit {
	eventForm!: FormGroup;
	channelForm!: FormGroup;
	channelList!: ChannelDto[];
	selectedChannelList!: ChannelDto[];
	displayedColumns: string[] = ['name', 'alias', 'textColor', 'backgroundColor', 'preview', 'actions'];

	eventId!: string;
	event!: LiveInteractiveAudioStreamingEventDto;
	imageHeaderURL!: string;
	imageFooterURL!: string;

	datetime = new UntypedFormControl();
	// date = new FormControl(moment());

	@ViewChild(MatTable) table!: MatTable<ChannelDto>;

	constructor(
		protected override activatedRoute: ActivatedRoute,
		protected override logService: LogService,
		protected override authService: AuthService,
		protected override companiesService: CompaniesService,
		private fb: FormBuilder,
		private dialog: MtxDialog,
		private snackbar: MatSnackBar,
		private router: Router,
		public titleService: Title,
		private eventsService: EventsService,
		private channelsService: ChannelsService,
		private angularFireStorage: AngularFireStorage
	) {
		super(activatedRoute, logService, authService, companiesService);

		try {
			this.titleService.setTitle(`${environment.title} | Evento`);

			this.loading = false;

			this.eventForm = this.fb.group({
				company: [null, Validators.required],
				name: [null, Validators.required],
				description: [null, Validators.required],
				date: [new FormControl(moment()), Validators.required],
				from: [null, Validators.required],
				to: [null, Validators.required],
				hostsNumber: [null, Validators.required],
				orientation: [null, Validators.required],
				audienceSize: [100, [Validators.required, Validators.min(100)]],
				idRequired: [null],
				headerText: [null],
				headerTextColor: ['#ffffff'],
				headerBackgroundColor: ['#ffffff'],
				headerImageUrl: [null],

				supportLink: [null],
				programLink: [null],
				linkTextColor: ['#ffffff'],
				linkBackgroundColor: ['#000000'],

				footerImageUrl: [null],
				footerImageLink: [null],
				footerBackgroundColor: ['#ffffff'],
			});

			this.channelForm = this.fb.group({
				channel: [null, Validators.required],
				alias: [null],
				textColor: ['#ffffff'],
				backgroundColor: ['#000000'],
			});
			this.channelList = new Array(0);
			this.selectedChannelList = new Array(0);
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	override async ngOnInit(): Promise<void> {
		try {
			await super.ngOnInit();

			this.loading = true;

			this.channelList = await this.channelsService.getAllChannels();
			this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') ?? '';

			if (this.eventId !== '') {
				await this.getEvent(this.eventId);
			}
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	addChannel(cf: FormGroupDirective): void {
		try {
			if (this.channelForm.valid) {
				const channelId = this.channelForm.get('channel')?.value;
				const channelExists = this.selectedChannelList.some(c => c.id === channelId);

				if (!channelExists) {
					const channel = this.channelList.filter(c => c.id === channelId)[0];

					if (channel !== null) {
						const alias = this.channelForm.get('alias')?.value ?? '';

						channel.alias = alias.length > 0 ? alias : null;
						channel.backgroundColor = this.channelForm.get('backgroundColor')?.value;
						channel.color = this.channelForm.get('textColor')?.value;

						this.selectedChannelList.push(channel);

						this.eventForm.patchValue({
							hostsNumber: this.selectedChannelList.length > 0 ? this.selectedChannelList.length : null,
						});

						this.table.renderRows();
					}
				} else {
					this.snackbar.open('¡El idioma ya está agregado!', undefined, {
						duration: 1000,
						panelClass: 'warn',
					});
				}

				this.resetChannelForm(cf);
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	removeChannel(channel: ChannelDto): void {
		try {
			const channelExists = this.selectedChannelList.some(l => l.id === channel.id);

			if (channelExists) {
				this.selectedChannelList = this.selectedChannelList.filter(l => l.id !== channel.id);

				this.eventForm.patchValue({
					hostsNumber: this.selectedChannelList.length > 0 ? this.selectedChannelList.length : null,
				});

				this.table.renderRows();
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async saveEvent(ef: FormGroupDirective, cf: FormGroupDirective): Promise<void> {
		try {
			if (this.eventForm.valid) {
				this.dialog.open({
					title: '¿Deseas guardar los cambios?',
					buttons: [
						{
							text: 'No',
							color: 'primary',
							onClick: () => {
								// do nothing
							},
						},
						{
							focusInitial: true,
							color: 'accent',
							text: 'Sí',
							onClick: async () => {
								try {
									this.loading = true;

									const event = new LiveInteractiveAudioStreamingEventDto();
									const date = moment(this.eventForm.get('date')?.value).format('YYYY-MM-DD');

									const fromDateString = `${date}T${moment(this.eventForm.get('from')?.value).format('HH:mm')}:00`;
									const fromDate = new Date(fromDateString);

									const toDateString = `${date}T${moment(this.eventForm.get('to')?.value).format('HH:mm')}:00`;
									const toDate = new Date(toDateString);

									event.company = this.selectedCompany ?? this.defaultCompany;
									event.audienceSize = this.eventForm.get('audienceSize')?.value;
									event.fromDate = fromDate.toISOString();
									event.hostsNumber = this.eventForm.get('hostsNumber')?.value;
									event.idRequired = this.eventForm.get('idRequired')?.value ?? false;
									event.isActive = true;
									event.isLive = this.eventId === '' ? false : this.event.isLive;
									event.isVertical = this.eventForm.get('orientation')?.value === 'v' ? true : false;
									event.channels = this.selectedChannelList;
									event.name = this.eventForm.get('name')?.value;
									event.notes = this.eventForm.get('description')?.value;
									event.toDate = toDate.toISOString();
									event.headerText = this.eventForm.get('headerText')?.value;
									event.headerTextColor = this.eventForm.get('headerTextColor')?.value;
									event.headerBackgroundColor = this.eventForm.get('headerBackgroundColor')?.value;
									event.headerImageUrl = this.imageHeaderURL;

									event.supportLink = this.eventForm.get('supportLink')?.value;
									event.programLink = this.eventForm.get('programLink')?.value;
									event.linkTextColor = this.eventForm.get('linkTextColor')?.value;
									event.linkBackgroundColor = this.eventForm.get('linkBackgroundColor')?.value;

									event.footerImageUrl = this.imageFooterURL;
									event.footerImageLink = this.eventForm.get('footerImageLink')?.value;
									event.footerBackgroundColor = this.eventForm.get('footerBackgroundColor')?.value;

									if (this.eventId === '') {
										await this.eventsService.addEvent(event);
									} else {
										event.id = this.eventId;
										await this.eventsService.updateEvent(event);
									}

									this.snackbar
										.open('¡Éxito! El evento fue guardado', undefined, {
											verticalPosition: 'top',
											duration: 1000,
											panelClass: 'success',
										})
										.afterDismissed()
										.subscribe(async () => {
											await this.router.navigateByUrl('/events');
										});

									this.selectedChannelList = new Array(0);
									this.table.renderRows();
									this.resetChannelForm(cf);

									this.resetEventForm(ef);
								} catch (e) {
									this.snackbar.open('¡Error! El evento no fue guardado', undefined, {
										duration: 1000,
										panelClass: 'danger',
									});

									this.logService.logError(e as Error);
								} finally {
									this.loading = false;
								}
							},
						},
					],
				});
			}
		} catch (e) {
			this.snackbar.open('¡Error! El evento no fue guardado', undefined, {
				duration: 1000,
				panelClass: 'danger',
			});

			this.logService.logError(e as Error);
		}
	}

	resetEventForm(ef: FormGroupDirective): void {
		try {
			ef.resetForm({
				name: null,
				description: null,
				date: null,
				from: null,
				to: null,
				hostsNumber: null,
				orientation: null,
				audienceSize: null,
				idRequired: null,
				headerText: null,
				headerTextColor: null,
				headerBackgroundColor: null,
				headerImageUrl: null,

				supportLink: null,
				programLink: null,
				linkTextColor: null,
				linkBackgroundColor: null,

				footerImageUrl: null,
				footerImageLink: null,
				footerBackgroundColer: null,
			});

			this.eventForm.markAsPristine();
			this.eventForm.markAsUntouched();
			this.eventForm.updateValueAndValidity();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	resetChannelForm(lf: FormGroupDirective): void {
		try {
			lf.resetForm({
				channel: null,
				alias: null,
				textColor: '#ffffff',
				backgroundColor: '#000000',
			});

			this.channelForm.markAsPristine();
			this.channelForm.markAsUntouched();
			this.channelForm.updateValueAndValidity();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async uploadImage(event: any, name: string): Promise<void> {
		if (event.target.files.length > 0) {
			const path = event.target.files[0];
			const srcheaderImageUrl = this.angularFireStorage.upload('/Banners/' + path.name, path);
			(await srcheaderImageUrl).ref.getDownloadURL().then(url => {
				if (name === 'header') {
					this.fb.group({ imageHeaderUrl: url });
					this.imageHeaderURL = url;
				} else if (name === 'footer') {
					this.fb.group({ imageFooterUrl: url });
					this.imageFooterURL = url;
				}
			});
		}
	}

	async getEvent(eventId: string): Promise<void> {
		try {
			this.loading = true;

			this.event = await this.eventsService.getEvent(eventId);

			console.log(this.event);

			let company: CompanyDto | undefined;
			if (this.event.company !== null) {
				company = this.companyList.find(c => c.id === this.event.company.id);
			}

			this.eventForm.patchValue({
				company: company,
				name: this.event.name,
				description: this.event.notes,
				date: moment(this.event.fromDate).format('YYYY-MM-DD'),
				from: moment(this.event.fromDate).toDate(),
				to: moment(this.event.toDate).toDate(),
				hostsNumber: this.event.channels.length > 0 ? this.event.channels.length : null,
				orientation: this.event.isVertical ? 'v' : 'h',
				audienceSize: this.event.audienceSize,
				idRequired: this.event.idRequired,
				headerText: this.event.headerText,
				headerTextColor: this.event.headerTextColor,
				headerBackgroundColor: this.event.headerBackgroundColor,
				headerImageUrl: this.event.headerImageUrl,

				supportLink: this.event.supportLink,
				programLink: this.event.programLink,
				linkTextColor: this.event.linkTextColor,
				linkBackgroundColor: this.event.linkBackgroundColor,

				footerImageUrl: this.event.footerImageUrl,
				footerImageLink: this.event.footerImageLink,
				footerBackgroundColor: this.event.footerBackgroundColor,
			});

			this.imageHeaderURL = this.event.headerImageUrl;
			this.imageFooterURL = this.event.footerImageUrl;

			this.event.channels.forEach(channel => {
				this.selectedChannelList.push(channel);
			});
			this.table.renderRows();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	setWhatsappLink(): void {
		this.eventForm.patchValue({
			supportLink: 'https://wa.me/521',
		});
	}

	setTelegramLink(): void {
		this.eventForm.patchValue({
			supportLink: 'https://t.me/+521',
		});
	}

	setMessengerLink(): void {
		this.eventForm.patchValue({
			supportLink: 'https://m.me/',
		});
	}
}
