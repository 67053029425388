import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, AuthPipe, customClaims, loggedIn } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import firebase from 'firebase/compat/app';
import { forkJoin, Observable, of, pipe } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AddUpdateChannelComponent } from './components/add-update-channel/add-update-channel.component';
import { AddUpdateCompanyComponent } from './components/add-update-company/add-update-company.component';
import { AddUpdateEventComponent } from './components/add-update-event/add-update-event.component';
import { AddUpdateUserComponent } from './components/add-update-user/add-update-user.component';
import { AttendeesComponent } from './components/attendees/attendees.component';
import { AudienceEmbeddableComponent } from './components/audience-embeddable/audience-embeddable.component';
import { AudienceComponent } from './components/audience/audience.component';
import { ChannelsComponent } from './components/channels/channels.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventsComponent } from './components/events/events.component';
import { HostComponent } from './components/host/host.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { StreamsComponent } from './components/streams/streams.component';
import { UsersComponent } from './components/users/users.component';

const combineAngularFireAuthGuardPipes = (authPipes: AuthPipe[]) => switchMap((user: Observable<firebase.User>) => forkJoin(authPipes.map(pipe => pipe(user))));

const isSignedIdAndAdmin = pipe(
	map((user: firebase.User) => of(user)),
	combineAngularFireAuthGuardPipes([loggedIn, customClaims as AuthPipe]),
	map(([isLoggedIn, customClaimList]) => {
		return {
			loggedIn: isLoggedIn,
			customClaims: customClaimList,
		};
	}),
	map(x => {
		if ((x.customClaims as any).admin === true) {
			return true;
		}

		if (!x.loggedIn) {
			return ['sign-in'];
		}

		return ['events'];
	})
);

const isSignedIdAndUser = pipe(
	map((t: firebase.User) => of(t)),
	combineAngularFireAuthGuardPipes([loggedIn, customClaims as AuthPipe]),
	map(([isLoggedIn, customClaimList]) => {
		return {
			loggedIn: isLoggedIn,
			customClaims: customClaimList,
		};
	}),
	map(t => {
		if (t.loggedIn) {
			return true;
		}

		if (!t.loggedIn) {
			return ['sign-in'];
		}

		return ['events'];
	})
);

const routes: Routes = [
	{
		path: '',
		component: NavigationComponent,
		children: [
			{ path: '', redirectTo: '/events', pathMatch: 'full' },
			{ path: 'sign-in', component: SignInComponent },
			{ path: 'dashboard', component: DashboardComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: () => isSignedIdAndAdmin } },
			{ path: 'events', component: EventsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: () => isSignedIdAndUser } },
			{
				path: 'events/new',
				component: AddUpdateEventComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndUser },
			},
			{
				path: 'events/:eventId',
				component: AddUpdateEventComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndUser },
			},
			{
				path: 'events/:eventId/host',
				component: HostComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndUser },
			},
			{
				path: 'events/:eventId/attendees',
				component: AttendeesComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndUser },
			},
			{
				path: 'streams',
				component: StreamsComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndUser },
			},
			{
				path: 'attendees',
				component: AttendeesComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndUser },
			},
			{ path: 'channels', component: ChannelsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: () => isSignedIdAndAdmin } },
			{
				path: 'channels/new',
				component: AddUpdateChannelComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndAdmin },
			},
			{
				path: 'channels/:channelId',
				component: AddUpdateChannelComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndAdmin },
			},
			{ path: 'users', component: UsersComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: () => isSignedIdAndAdmin } },
			{
				path: 'users/new',
				component: AddUpdateUserComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndAdmin },
			},
			{
				path: 'users/:userId',
				component: AddUpdateUserComponent,
				canActivate: [AngularFireAuthGuard],
			},
			{ path: 'companies', component: CompaniesComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: () => isSignedIdAndAdmin } },
			{
				path: 'companies/new',
				component: AddUpdateCompanyComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndAdmin },
			},
			{
				path: 'companies/:companyId',
				component: AddUpdateCompanyComponent,
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: () => isSignedIdAndAdmin },
			},
		],
	},
	{
		path: 'events/:eventId/audience',
		component: AudienceComponent,
	},
	{
		path: 'events/:eventId/audience/embeddable',
		component: AudienceEmbeddableComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
