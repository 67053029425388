import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { CompanyDto } from 'src/app/models/company-dto';
import { CompaniesService } from 'src/app/services/companies.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-add-update-company',
	templateUrl: './add-update-company.component.html',
	styleUrls: ['./add-update-company.component.scss'],
})
export class AddUpdateCompanyComponent implements OnInit {
	loading!: boolean;

	companyForm!: FormGroup;

	companyId!: string;
	company!: CompanyDto;

	constructor(
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private dialog: MtxDialog,
		private snackbar: MatSnackBar,
		private router: Router,
		public titleService: Title,
		private logService: LogService,
		private companiesService: CompaniesService
	) {
		try {
			this.titleService.setTitle(`${environment.title} | Compañía`);

			this.loading = false;

			this.companyForm = this.fb.group({
				name: [null, Validators.required],
			});
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;

			this.companyId = this.route.snapshot.paramMap.get('companyId') ?? '';

			if (this.companyId !== '') {
				await this.getCompany(this.companyId);
			}
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async saveCompany(cf: FormGroupDirective): Promise<void> {
		try {
			if (this.companyForm.valid) {
				this.dialog.open({
					title: '¿Deseas guardar los cambios?',
					buttons: [
						{
							text: 'No',
							color: 'primary',
							onClick: () => {
								// do nothing
							},
						},
						{
							focusInitial: true,
							color: 'accent',
							text: 'Sí',
							onClick: async () => {
								try {
									this.loading = true;

									const company = new CompanyDto();
									company.name = this.companyForm.get('name')?.value;

									if (this.companyId === '') {
										await this.companiesService.addCompany(company);
									} else {
										company.id = this.companyId;
										await this.companiesService.updateCompany(company);
									}

									this.snackbar
										.open('¡Éxito! La compañía fue guardada', undefined, {
											verticalPosition: 'top',
											duration: 1000,
											panelClass: 'success',
										})
										.afterDismissed()
										.subscribe(async () => {
											await this.router.navigateByUrl('/companies');
										});

									this.resetCompanyForm(cf);
								} catch (e) {
									this.snackbar.open('¡Error! La compañía no fue guardada', undefined, {
										duration: 1000,
										panelClass: 'danger',
									});

									this.logService.logError(e as Error);
								} finally {
									this.loading = false;
								}
							},
						},
					],
				});
			}
		} catch (e) {
			this.snackbar.open('¡Error! La compañía no fue guardada', undefined, {
				duration: 1000,
				panelClass: 'danger',
			});

			this.logService.logError(e as Error);
		}
	}

	resetCompanyForm(cf: FormGroupDirective): void {
		try {
			cf.resetForm({
				name: null,
			});

			this.companyForm.markAsPristine();
			this.companyForm.markAsUntouched();
			this.companyForm.updateValueAndValidity();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getCompany(companyId: string): Promise<void> {
		try {
			this.loading = true;

			this.company = await this.companiesService.getCompany(companyId);

			this.companyForm.patchValue({
				name: this.company.name,
			});
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}
}
