import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import AgoraRTC, { AudienceLatencyLevelType, IAgoraRTCClient, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import AgoraRTM, { RtmChannel, RtmClient, RtmMessage, RtmStatusCode, RtmTextMessage } from 'agora-rtm-sdk';
import { TokenIds } from 'src/app/enums/token-ids.enum';
import { ChannelDto } from 'src/app/models/channel-dto';
import { EventMessageDto } from 'src/app/models/event-message-dto';
import { LiveInteractiveAudioStreamingEventDto } from 'src/app/models/live-interactive-audio-streaming-event-dto';
import { MessageDto } from 'src/app/models/message-dto';
import { TextMessageDto } from 'src/app/models/text-message-dto';
import { AuthService } from 'src/app/services/auth-service.service';
import { EventsService } from 'src/app/services/events.service';
import { IDService } from 'src/app/services/id.service';
import { LogService } from 'src/app/services/log.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-host',
	templateUrl: './host.component.html',
	styleUrls: ['./host.component.scss'],
})
export class HostComponent implements OnInit, OnDestroy {
	loading!: boolean;
	waiting!: boolean;

	appId!: string;
	eventId!: string;
	event!: LiveInteractiveAudioStreamingEventDto;

	channelsDisabled!: boolean;
	channelList!: ChannelDto[];
	selectedChannel!: string;

	micsDisabled!: boolean;
	micList!: MediaDeviceInfo[];
	selectedMic!: string;

	isMicEnabled!: boolean;
	micClass!: string;
	isCallActive!: boolean;
	callClass!: string;

	rtcClient!: IAgoraRTCClient;
	rtmClient!: RtmClient;
	rtmClientReconnected!: boolean;

	rtcUserId!: string;
	rtmUserId!: string;

	rtcToken!: string;
	rtmToken!: string;

	rtmChannel!: RtmChannel;
	rtmChannelMemberCount!: number;

	audioTrack!: IMicrophoneAudioTrack;

	constructor(
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private snackBar: MatSnackBar,
		private logService: LogService,
		private eventsService: EventsService,
		private authService: AuthService,
		private idService: IDService
	) {
		try {
			this.rtmClientReconnected = false;

			this.event = new LiveInteractiveAudioStreamingEventDto();
			this.event.isActive = false;
			this.event.isLive = false;

			this.channelsDisabled = false;
			this.channelList = new Array(0);

			this.micsDisabled = false;
			this.micList = new Array(0);

			this.isMicEnabled = false;
			this.micClass = 'control mic isDisabled';

			this.isCallActive = false;
			this.callClass = 'control call';
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;

			this.micList = await AgoraRTC.getMicrophones();
			this.selectedMic = this.micList[0]?.deviceId;

			AgoraRTC.onMicrophoneChanged = async () => {
				this.micList = await AgoraRTC.getMicrophones();
			};

			this.eventId = this.activatedRoute.snapshot.paramMap.get('eventId') ?? '';
			await this.getEvent(this.eventId);

			await this.initRtmClient();
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async ngOnDestroy(): Promise<void> {
		try {
			await this.rtcClient?.leave();

			await this.rtmChannel?.leave();

			await this.rtmClient?.logout();
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async initRtmClient(): Promise<void> {
		try {
			this.rtmUserId = `${await this.authService.getUserId()}${this.idService.getRandomId()}`;

			this.rtmToken = (await this.eventsService.getHostToken(this.eventId, TokenIds.RTM, this.rtmUserId)).rtm;

			this.rtmClient = AgoraRTM.createInstance(environment.agoraId);
			this.rtmClient.on('ConnectionStateChanged', (newState: RtmStatusCode.ConnectionState, reason: RtmStatusCode.ConnectionChangeReason) => {
				if (newState === AgoraRTM.ConnectionState.RECONNECTING) {
					this.rtmClientReconnected = true;
				} else if (newState == AgoraRTM.ConnectionState.CONNECTED) {
					if (this.rtmClientReconnected) {
						this.rtmClientReconnected = false;
					}
				}

				this.logService.logInformation(`ConnectionStateChanged: ${newState} due to ${reason}`);
			});

			await this.rtmClient.login({
				uid: this.rtmUserId,
				token: this.rtmToken,
			});

			this.rtmChannel = await this.rtmClient.createChannel(this.eventId);
			this.rtmChannel.on('ChannelMessage', (rtmMessage: RtmMessage, memberId: string) => {
				if (rtmMessage.messageType === 'TEXT') {
					const rtmTextMessage = rtmMessage as RtmTextMessage;
					const message = JSON.parse(rtmTextMessage.text) as MessageDto;

					if (message.type === 'TextMessageDto') {
						const textMessage = message as TextMessageDto;
					} else if (message.type === 'EventMessageDto') {
						const eventMessage = message as EventMessageDto;

						if (eventMessage.event === 'ActiveStateChanged') {
							this.activeStateChanged(this.eventId, eventMessage.args as boolean);
						} else if (eventMessage.event === 'LiveStateChanged') {
							this.liveStateChanged(this.eventId, eventMessage.args as boolean);
						}
					}
				}

				this.logService.logInformation(`ChannelMessage: ${rtmMessage}`);
			});
			this.rtmChannel.on('MemberCountUpdated', (memberCount: number) => {
				this.rtmChannelMemberCount = memberCount;

				this.rtmChannel.getMembers().then(members => {
					console.log(members);
				});
			});

			await this.rtmChannel.join();
		} catch (e) {
			console.error(e);
			// this.logService.logError(e as Error);
		}
	}

	async initRtcClient(): Promise<void> {
		try {
			if (this.selectedChannel === undefined || this.selectedChannel === '') {
				return;
			}

			this.rtcUserId = this.selectedChannel;

			if (this.rtcClient === undefined) {
				this.rtcClient = AgoraRTC.createClient({
					mode: 'live',
					role: 'host',
					clientRoleOptions: {
						level: AudienceLatencyLevelType.AUDIENCE_LEVEL_ULTRA_LOW_LATENCY,
					},
					codec: 'vp8',
				});
			}

			if (this.audioTrack === undefined) {
				this.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
				this.audioTrack.setEnabled(false);
			}

			this.rtcToken = (await this.eventsService.getHostToken(this.eventId, TokenIds.RTC, this.rtcUserId)).rtc;
		} catch (e) {
			this.logService.logError(e as Error);
		} finally {
			this.loading = false;
		}
	}

	async activeStateChanged(eventId: string, isActive: boolean): Promise<void> {
		try {
			this.event.isActive = isActive;

			if (!this.event.isActive) {
				this.leaveStream();

				this.snackBar.open('Micrófono activado', undefined, {
					panelClass: 'textalign',
					duration: 1000,
					horizontalPosition: 'center',
					verticalPosition: 'top',
				});
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async liveStateChanged(eventId: string, isLive: boolean): Promise<void> {
		try {
			this.waiting = !isLive;
			this.event.isLive = isLive;

			if (this.event.isLive) {
				this.rtcToken = (await this.eventsService.getHostToken(eventId, TokenIds.RTC, this.rtcUserId)).rtc;
			}

			if (this.event.isLive) {
				// INFO: Auto join is not for host
				// this.joinStream();
			} else {
				this.leaveStream();
				this.toggleMic();
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async toggleMic(): Promise<void> {
		if (!this.isMicEnabled) {
			// Unmute Mic

			if (this.selectedChannel === undefined || this.selectedChannel === '') {
				this.snackBar.open('Necesitas seleccionar canal y micrófono', undefined, {
					duration: 1000,
					horizontalPosition: 'center',
					verticalPosition: 'top',
				});

				return;
			}

			this.audioTrack.setEnabled(true);

			this.snackBar.open('Micrófono activado', undefined, {
				panelClass: 'textalign',
				duration: 1000,
				horizontalPosition: 'center',
				verticalPosition: 'top',
			});

			this.isMicEnabled = true;
			this.micClass = 'control mic';

			this.draw();
		} else {
			// Mute Mic

			this.audioTrack.setEnabled(false);

			this.snackBar.open('Micrófono desactivado', undefined, {
				duration: 1000,
				horizontalPosition: 'center',
				verticalPosition: 'top',
			});

			this.isMicEnabled = false;
			this.micClass = 'control mic isDisabled';

			if (this.canvasContext !== null) {
				this.canvasContext.clearRect(0, 0, this.WIDTH, this.HEIGHT);
			}

			if (this.gain !== null) {
				this.gain.gain.value = 0;
			}

			this.mediaStream.getTracks().forEach(track => {
				track.stop();
			});

			cancelAnimationFrame(this.drawId);
		}
	}

	async toggleStream(): Promise<void> {
		try {
			// await this.toggleMic();

			if (this.isCallActive) {
				await this.leaveStream();
			} else {
				await this.joinStream();
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async toggle(): Promise<void> {
		await this.toggleMic();
		await this.toggleStream();
	}

	async joinStream(): Promise<void> {
		try {
			if (this.event.isActive && this.event.isLive && this.rtcClient.connectionState === 'DISCONNECTED') {
				if (this.isMicEnabled) {
					if (this.rtcToken === undefined) {
						this.rtcToken = (await this.eventsService.getHostToken(this.eventId, TokenIds.RTC, this.rtcUserId)).rtc;
					}

					// Join stream
					await this.rtcClient.join(environment.agoraId, this.eventId, this.rtcToken, this.rtcUserId);

					// Publish audio track
					this.audioTrack.setDevice(this.selectedMic);
					await this.rtcClient.publish(this.audioTrack);

					this.isCallActive = true;
					this.callClass = 'control call isActive';

					this.channelsDisabled = false;
					this.micsDisabled = true;
				} else {
					this.snackBar.open('Debes activar el micrófono', undefined, {
						duration: 1000,
						horizontalPosition: 'center',
						verticalPosition: 'top',
					});
				}
			}
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async leaveStream(): Promise<void> {
		try {
			// Unpublish audio track
			await this.rtcClient?.unpublish(this.audioTrack);

			// Leave stream
			await this.rtcClient?.leave();

			this.isCallActive = false;
			this.callClass = 'control call';

			this.channelsDisabled = false;
			this.micsDisabled = false;
		} catch (e) {
			this.logService.logError(e as Error);
		}
	}

	async getEvent(eventId: string): Promise<void> {
		this.event = await this.eventsService.getEvent(eventId);
		this.titleService.setTitle(`${environment.title} | Anfitrión - ${this.event.name}`);

		const selectOne = new ChannelDto();
		selectOne.id = '';
		selectOne.alias = '';
		this.channelList.push(selectOne);

		this.event.channels.forEach(channel => {
			channel.isDisabled = true;
			this.channelList.push(channel);
		});

		this.waiting = !this.event.isLive;
	}

	async onChannelSelected(args: MatSelectChange): Promise<void> {
		if (args.value) {
			const channelId = args.value;
			if (channelId !== '') {
				await this.initRtcClient();

				if (this.isCallActive) {
					await this.toggleStream();
					await this.toggleStream();
				}
			} else {
				// do nothing
			}
		}
	}

	onMicSelected(args: MatSelectChange): void {
		if (args.value) {
			const micId = args.value;
		}
	}

	audioContext!: AudioContext;
	analyser!: AnalyserNode;
	waveShaper!: WaveShaperNode;
	gain!: GainNode;
	biquadFilter!: BiquadFilterNode;

	@ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
	canvasContext!: CanvasRenderingContext2D | null;

	drawId!: number;

	buffer!: Uint8Array;
	bufferLength!: number;

	WIDTH!: number;
	HEIGHT!: number;
	mediaStream!: MediaStream;
	source!: MediaStreamAudioSourceNode;

	async draw(): Promise<void> {
		this.audioContext = new AudioContext();
		this.analyser = this.audioContext.createAnalyser();
		this.analyser.minDecibels = -90;
		this.analyser.maxDecibels = -10;
		this.analyser.smoothingTimeConstant = 0.85;
		this.analyser.fftSize = 256;

		this.waveShaper = this.audioContext.createWaveShaper();
		this.waveShaper.oversample = '4x';

		this.gain = this.audioContext.createGain();
		this.gain.gain.value = 1;

		this.biquadFilter = this.audioContext.createBiquadFilter();
		this.biquadFilter.gain.setTargetAtTime(0, this.audioContext.currentTime, 0);

		this.canvasContext = this.canvas.nativeElement.getContext('2d');

		this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });

		this.source = this.audioContext.createMediaStreamSource(this.mediaStream);
		this.source.connect(this.waveShaper);
		this.waveShaper.connect(this.biquadFilter);
		this.biquadFilter.connect(this.gain);
		this.gain.connect(this.analyser);

		this.WIDTH = this.canvas.nativeElement.width;
		this.HEIGHT = this.canvas.nativeElement.height;

		this.bufferLength = this.analyser.frequencyBinCount;
		this.buffer = new Uint8Array(this.bufferLength);

		if (this.canvasContext !== null) {
			this.canvasContext.clearRect(0, 0, this.WIDTH, this.HEIGHT);
		}

		this.drawing();
	}

	drawing(): void {
		this.drawId = requestAnimationFrame(this.drawing.bind(this));

		this.buffer = new Uint8Array(this.bufferLength);
		this.analyser.getByteFrequencyData(this.buffer);
		if (this.canvasContext !== null) {
			this.canvasContext.fillStyle = 'rgb(0, 0, 0)';
			this.canvasContext.fillRect(0, 0, this.WIDTH, this.HEIGHT);

			const barWidth = (this.WIDTH / this.bufferLength) * 5;
			let barHeight;
			let x = 0;

			for (let i = 0; i < this.bufferLength; i++) {
				barHeight = this.buffer[i];

				this.canvasContext.fillStyle = `rgb(0, 150, 136)`;
				this.canvasContext.fillRect(x, this.HEIGHT - barHeight / 5, barWidth, barHeight / 5);

				x += barWidth + 1;
			}
		}
	}
}
