import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-license',
  templateUrl: './user-license.component.html',
  styleUrls: ['./user-license.component.scss']
})
export class UserLicenseComponent {
  constructor(
    public dialogRef: MatDialogRef<UserLicenseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}
