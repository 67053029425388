import { BaseDto } from './base-dto';
import { CompanyDto } from './company-dto';

export class UserDto extends BaseDto {
	id!: string;
	firebaseId!: string;
	displayName!: string;
	email!: string;
	emailVerified!: boolean;
	disabled!: boolean;

	company!: CompanyDto;

	role!: string;
}
