import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CompanyDto } from '../models/company-dto';

@Injectable({
	providedIn: 'root',
})
export class CompaniesService {
	constructor(private httpClient: HttpClient, public auth: AngularFireAuth) {
		// do nothing
	}

	async getAllCompanies(): Promise<CompanyDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<CompanyDto[]>(`${environment.apiUrl}/api/companies`, {
				headers: headers,
			}));
	}

	async getCompanies(page?: number, pageSize?: number): Promise<CompanyDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<CompanyDto[]>(`${environment.apiUrl}/api/companies?&page=${page}&pageSize=${pageSize}`, {
				headers: headers,
			}));
	}

	async getCompaniesLength(): Promise<number> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<number>(`${environment.apiUrl}/api/companies/length`, {
				headers: headers,
			}));
	}

	async getCompany(companyId: string): Promise<CompanyDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		if (companyId?.length > 0) {
			return await firstValueFrom(this.httpClient
				.get<CompanyDto>(`${environment.apiUrl}/api/companies/${companyId}`, {
					headers: headers,
				}));
		} else {
			return await firstValueFrom(new Observable<CompanyDto>(next => {
				next.next(undefined);
				next.complete();
			}));
		}
	}

	async addCompany(company: CompanyDto): Promise<CompanyDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.post<CompanyDto>(`${environment.apiUrl}/api/companies`, company, {
				headers: headers,
			}));
	}

	async updateCompany(company: CompanyDto): Promise<CompanyDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.put<CompanyDto>(`${environment.apiUrl}/api/companies/${company.id}`, company, {
				headers: headers,
			}));
	}
}
