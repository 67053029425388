import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { TokenIds } from '../enums/token-ids.enum';
import { EventDto } from '../models/event-dto';
import { LiveInteractiveAudioStreamingEventDto } from '../models/live-interactive-audio-streaming-event-dto';
import { TokenDto } from '../models/token-dto';

@Injectable({
	providedIn: 'root',
})
export class EventsService {
	constructor(private httpClient: HttpClient, public auth: AngularFireAuth) {
		// do nothing
	}

	async getEvents(): Promise<EventDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<EventDto[]>(`${environment.apiUrl}/api/events`, {
				headers: headers,
			}));
	}

	async getEventsBy(companyId?: string, isActive?: boolean, isLive?: boolean, page?: number, pageSize?: number): Promise<EventDto[]> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<EventDto[]>(
				`${environment.apiUrl}/api/events
				?companyId=${companyId !== undefined ? companyId : ''}
				&isActive=${isActive !== undefined ? isActive : ''}
				&isLive=${isLive !== undefined ? isLive : ''}
				&page=${page}
				&pageSize=${pageSize}`,
				{
					headers: headers,
				}
			));
	}

	async getEventsByLength(companyId?: string, isActive?: boolean, isLive?: boolean): Promise<number> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.get<number>(
				`${environment.apiUrl}/api/events/length
				?companyId=${companyId !== undefined ? companyId : ''}
				&isActive=${isActive !== undefined ? isActive : ''}
				&isLive=${isLive !== undefined ? isLive : ''}`,
				{
					headers: headers,
				}
			));
	}

	async getEvent(eventId: string): Promise<LiveInteractiveAudioStreamingEventDto> {
		if (eventId?.length > 0) {
			return await firstValueFrom(this.httpClient.get<LiveInteractiveAudioStreamingEventDto>(`${environment.apiUrl}/api/events/${eventId}`));
		} else {
			return await firstValueFrom(new Observable<LiveInteractiveAudioStreamingEventDto>(next => {
				next.next(undefined);
				next.complete();
			}));
		}
	}

	async addEvent(event: EventDto): Promise<EventDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.post<EventDto>(`${environment.apiUrl}/api/events`, event, {
				headers: headers,
			}));
	}

	async updateEvent(event: EventDto): Promise<EventDto> {
		const user = await this.auth.currentUser;
		const token = await user?.getIdToken();

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		});

		return await firstValueFrom(this.httpClient
			.put<EventDto>(`${environment.apiUrl}/api/events/${event.id}`, event, {
				headers: headers,
			}));
	}

	async getHostToken(eventId: string, tokenId: TokenIds, userId: string): Promise<TokenDto> {
		if (eventId?.length > 0 && userId?.length > 0) {
			const user = await this.auth.currentUser;
			const token = await user?.getIdToken();

			const headers = new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			});

			return await firstValueFrom(await this.httpClient
				.get<TokenDto>(`${environment.apiUrl}/api/events/${eventId}/host/tokens/${tokenId}/${userId}`, {
					headers: headers,
				}));
		} else {
			return await firstValueFrom(new Observable<TokenDto>(next => {
				next.next(undefined);
				next.complete();
			}));
		}
	}

	async getAudienceToken(eventId: string, userId: string): Promise<TokenDto> {
		if (eventId?.length > 0 && userId?.length > 0) {
			return await firstValueFrom(this.httpClient.get<TokenDto>(`${environment.apiUrl}/api/events/${eventId}/audience/tokens/${userId}`));
		} else {
			return await firstValueFrom(new Observable<TokenDto>(next => {
				next.next(undefined);
				next.complete();
			}));
		}
	}
}
